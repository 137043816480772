import React, { useState } from "react"
import {
  BiSupport,
  RiLightbulbFlashLine,
  HiOutlineDocumentSearch,
  IoFunnel,
  RiAdvertisementLine,
  AiOutlineBarChart,
} from "react-icons/all";

function HowWorks() {
  return (
    <section className="w-full flex bg-white">
      <div className="flex flex-col py-16 text-white text-center items-center">
        <div
          className="flex flex-col items-center text-center space-y-1
                md:space-y-2"
        >
          <h1
            className="text-3xl link font-bold text-black
                      lg:text-5xl md:text-4xl"
          >
            How It Works
          </h1>
          <div className="h-px bg-gray-500 w-8/12 sm:w-5/12 md:w-7/12 lg:w-9/12"></div>
          <h1
            className="text-lg link font-normal text-black
                      lg:text-2xl md:text-xl"
          >
            How Our Paid Ads Lead Generation Campaigns Work
          </h1>
        </div>
        <div
          className="w-full grid gap-5 grid-cols-1 text-center 
        md:grid-cols-2 lg:grid-cols-3 mt-9 mx-2 px-5 md:px-10 lg:px-32"
        >
          <div className="text-black items-center flex flex-col bg-white py-6 rounded-md cursor-pointer">
            <div
              className="shadow mx-6 rounded-full w-20 h-20 items-center 
            flex justify-center font-bold text-sm lg:text-base"
            >
              <RiLightbulbFlashLine className="text-blue-700 text-5xl " />
            </div>
            <h1 className="text-xl lg:text-2xl font-bold text-black  m-3">
              1. Understand Your Business
            </h1>
            <h1 className="text-base m-3 font-medium text-justify text-gray-500">
              We start off every client by first understanding their business,
              ideal clients, and best offers for Facebook, Linked In, and Google
              ads. Whether you're trying to maintain a 10% growth rate or
              explode your MRR we're here to help.
            </h1>
          </div>
          <div className="text-black items-center flex flex-col bg-white py-6 rounded-md cursor-pointer">
            <div
              className="shadow mx-6 rounded-full w-20 h-20 items-center 
            flex justify-center font-bold text-sm lg:text-base"
            >
              <HiOutlineDocumentSearch className="text-blue-700 text-5xl " />
            </div>
            <h1 className="text-xl lg:text-2xl font-bold text-black  m-3">
              2. Research Audience
            </h1>
            <h1 className="text-base font-medium m-3 text-justify text-gray-500 ">
              After getting an overview of your goals, we develop alongside you
              an ideal buyer persona that we can target through advertising.
            </h1>
          </div>
          <div className="text-black items-center flex flex-col bg-white py-6 rounded-md cursor-pointer">
            <div
              className="shadow mx-6 rounded-full w-20 h-20 items-center 
            flex justify-center font-bold text-sm lg:text-base"
            >
              <IoFunnel className="text-blue-700 text-5xl " />
            </div>
            <h1 className="text-xl lg:text-2xl font-bold text-black  m-3">
              3. Create Sales Funnel
            </h1>
            <h1 className="text-base font-medium mx-6 lg:mx10 text-justify text-gray-500 ">
              Next, we work on your funnel to take a cold prospect into a
              customer. We develop the landing pages, create the copy and
              install the pixel if necessary.
            </h1>
          </div>
          <div className="text-black items-center flex flex-col bg-white py-6 rounded-md cursor-pointer">
            <div
              className="shadow mx-6 rounded-full w-20 h-20 items-center 
            flex justify-center font-bold text-sm lg:text-base"
            >
              <RiAdvertisementLine className="text-blue-700 text-5xl " />
            </div>
            <h1 className="text-xl lg:text-2xl font-bold text-black  m-3">
              4. Create Ad Campaign
            </h1>
            <h1 className="text-base font-medium mx-6 lg:mx10 text-justify text-gray-500 ">
              Finally, we apply all we learned to create an ad that converts and
              generates calls booked or sign-ups. We work on several creatives
              and test different targeting to bring you your ideal customers.
            </h1>
          </div>
          <div className="text-black items-center flex flex-col bg-white py-6 rounded-md cursor-pointer">
            <div
              className="shadow mx-6 rounded-full w-20 h-20 items-center 
            flex justify-center font-bold text-sm lg:text-base"
            >
              <AiOutlineBarChart className="text-blue-700 text-5xl " />
            </div>
            <h1 className="text-xl lg:text-2xl font-bold text-black  m-3">
              5. Scale Course Sales
            </h1>
            <h1 className="text-base font-medium  mx-6 lg:mx10 text-justify text-gray-500 ">
              Once prospects see your ad and check out your landing page, they
              will sign up for your software or book a demo. Then our job is to
              scale those campaigns and help you increase monetization by
              targeting previous buyers.
            </h1>
          </div>
          <div className="text-black items-center flex flex-col bg-white py-6 rounded-md cursor-pointer">
            <div
              className="shadow mx-6 rounded-full w-20 h-20 items-center 
            flex justify-center font-bold text-sm lg:text-base"
            >
              <BiSupport className="text-blue-700 text-5xl " />
            </div>
            <h1 className="text-xl lg:text-2xl font-bold text-black  m-3">
              6. Unlimited Support
            </h1>
            <h1 className="text-base font-medium  mx-6 lg:mx10 text-justify text-gray-500 ">
              After analyzing the results of the campaign, we tweak and test new
              variables to get you the best results, as well as work with you to
              optimize the type of leads you are getting.
            </h1>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowWorks;
