import React from "react";
import Bg from "../../image/bg.jpg";
import { GoPrimitiveDot } from "react-icons/go";
import Fade from "react-reveal/Fade";

function Tittle({ title }) {
  return (
    <div className="h-96 bg-about bg-center bg-fixed">
      <Fade>
        <div
          className="space-y-2 h-full w-full flex flex-col bg-black bg-opacity-60
          justify-center items-center"
        >
          <h1 className="text-white text-5xl font-bold">{title}</h1>
          <div className="flex text-base text-white items-center space-x-1">
            <h1>Home</h1>
            <GoPrimitiveDot className="text-green-400 mt-px" />
            <h1>{title}</h1>
          </div>
        </div>
      </Fade>
    </div>
  );
}
export default Tittle;
