import React, { useState } from "react";
import Header from "../main-com/Header";
import Footer from "../main-com/Footer";
import Tittle from "../sub-com/Tittle";
import Contact from "../sub-com/ContactUs";
import { HiOutlineMail } from "react-icons/hi";
import { IoLocationOutline } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";

function ContactComponent() {
  const [state, setState] = useState(false);

  setInterval(() => {
    setState(true);
  }, 1000);

  return (
    <div>
      {state ? (
        <div>
          <Header />
          <Tittle title={"Contact Us"} />
          <div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 
        content-center place-content-center text-center pt-12
        mx-5 sm:mx-9 md:mx-12 lg:mx-30 xl:mx-40"
          >
            <div
              className="text-black hover:text-white shadow-lg rounded-lg md:row-span-2 lg:row-span-1
        py-10 space-y-2 justify-center items-center flex flex-col 
        hover:bg-blue-500 transition duration-700 ease-in-out"
            >
              <div className="p-3 rounded-full border-2 border-dotted bg-white  border-blue-700 ">
                <IoLocationOutline className="text-blue-700 text-2xl " />
              </div>
              <h1 className=" font-bold text-4xl">Address</h1>
              <h1 className="text-base font-normal mx-5">
                37/A Bharathiyaar 3rd street, Sivan Theatre 3rd street
                Kumarananthapuram, Tiruppur-641602, Tamil Nadu, India
              </h1>
            </div>
            <div
              className="text-black hover:text-white shadow-lg rounded-lg py-10 space-y-3 justify-center 
        items-center flex flex-col hover:bg-blue-500 transition duration-700 ease-in-out"
            >
              <div className="p-3 rounded-full border-2 border-dotted  bg-white  border-blue-700">
                <HiOutlineMail className="text-blue-700 text-2xl" />
              </div>
              <h1 className=" font-bold text-4xl">Email</h1>
              <h1 className="text-base font-normal">
                <a href="mailto:contact@benefitsusage.com">
                  contact@benefitsusage.com
                </a>
              </h1>
            </div>
            <div
              className="text-black hover:text-white shadow-lg rounded-lg py-10 space-y-4 justify-center 
        items-center flex flex-col hover:bg-blue-500 transition duration-700 ease-in-out"
            >
              <div className="p-3 rounded-full border-2 border-dotted bg-white border-blue-700">
                <FiPhoneCall className="text-blue-700 text-2xl" />
              </div>
              <h1 className=" font-bold text-4xl">Mobile</h1>
              <h1 className="text-base font-normal">
                <a href="tel:+91 9894974130">+91 9894974130</a>
              </h1>
            </div>
          </div>
          <Contact />
          <Footer />
        </div>
      ) : (
        <div className="grid place-content-center place-items-center h-screen">
          <span class="h-10 w-10">
            <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-10 w-10 bg-blue-500">
              <span className="grid place-content-center w-full place-items-center">
                <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
                <span class="inline-flex rounded-full h-5 w-5 bg-blue-600"></span>
              </span>
            </span>
          </span>
        </div>
      )}
    </div>
  );
}
export default ContactComponent;
