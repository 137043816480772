import React, { useState } from "react";
import Header from "../main-com/Header";
import Footer from "../main-com/Footer";
import one from "../../image/a-1.png";
import two from "../../image/a-2.jpg";
import three from "../../image/a-3.png";
import four from "../../image/a-4.png";
import five from "../../image/a-5.png";
import as1 from "../../image/as-1.png";
import as2 from "../../image/as-2.png";
import as3 from "../../image/as-3.png";
import as4 from "../../image/as-4.png";
import { TiArrowRightThick } from "react-icons/ti";
import { BsCheckBox } from "react-icons/bs";
import Fade from "react-reveal/Fade";
import "../../css/Banner.css";

function AppServiceComponent() {
  const [state, setState] = useState(false);

  setInterval(() => {
    setState(true);
  }, 2000);

  return (
   <div className="select-none">
      {state ? (
        <div>
          <Header />
          <div>
            <div
              className="lg:my-0 grid grid-cols-1 py-10 lg:grid-cols-2 gap-5 lg:gap-0
        place-content-center place-items-center"
            >
              <div className="px-5 mt-10 md:px-10 lg:px-14 lg:pl-52 text-center lg:text-left space-y-4">
                <h1 className="text-2xl sm:text-3xl lg:text-5xl link font-bold md:leading-6">
                  Awesome App For Your Modern Lifestyle
                </h1>
                <h1
                  className="text-sm sm:mx-4 md:mx-8 lg:mx-0 sm:text-sm 
            lg:text-base font-medium text-gray-400"
                >
                  Being a top mobile app development company, we provide mobile
                  app development solutions for the mobile plus web that will
                  retain the target audience, also increase the customer
                  engagement on the website and mobile app.
                </h1>
              </div>
              <div className="w-full flex justify-center lg:justify-end">
                <Fade>
                  <img src={one} alt="image" className="w-full  lg:w-11/12" />
                </Fade>
              </div>
            </div>
            <div className="py--5 lg:py-14">
              <div
                className="grid grid-cols-1 md:grid-cols-2 gap-10 
        content-center place-content-center text-center py-12
        mx-5 sm:mx-9 md:mx-12 lg:mx-30 xl:mx-40"
              >
                <div
                  className="mx-3
           text-left space-y-5"
                >
                  <h1
                    className="text-2xl link font-mediums text-black 
            font-bold sm:text-4xl lg:text-5xl"
                  >
                    Android Application Development
                  </h1>
                  <div className="flex flex-col items-center md:items-start">
                    <div id="loading-bar" className="bg-blue-700 h-1 w-24" />
                  </div>
                  <h1
                    className="text-sm mx-3 lg:mx-0 sm:text-sm lg:text-base 
            font-medium text-gray-500"
                  >
                    BenefitsUsage technologies is a leading android application
                    development company in Tamil Nadu. Our main focus is to
                    provide android app development services in India and all
                    over the world. We have a team of dedicated android app
                    developers. The main goal of android app development is to
                    provide such an android application that can fulfill a
                    customer's need. Android applications are playing a major
                    role in the service industry. So we are targeting to go to
                    such an extent where we can develop and deliver an android
                    application for every need.
                  </h1>
                  <ul
                    className="grid grid-rows-1 gap-2
            sm:gap-4 font-medium text-sm sm:text-base text-gray-900"
                  >
                    <li className="flex">
                      <div className="bg-transparent rounded-sm p-1 mr-2">
                        <TiArrowRightThick className="text-lg text-blue-500 " />
                      </div>
                      Social media Consultant
                    </li>
                    <li className="flex">
                      <div className="bg-transparent rounded-sm p-1 mr-2">
                        <TiArrowRightThick className="text-lg text-blue-500 " />
                      </div>
                      Customer Loyalty
                    </li>
                    <li className="flex">
                      <div className="bg-transparent rounded-sm p-1 mr-2">
                        <TiArrowRightThick className="text-lg text-blue-500 " />
                      </div>
                      Increased Sales
                    </li>
                    <li className="flex">
                      <div className="bg-transparent rounded-sm p-1 mr-2">
                        <TiArrowRightThick className="text-lg text-blue-500 " />
                      </div>
                      Instant Communication with Customers
                    </li>
                    <li className="flex">
                      <div className="bg-transparent rounded-sm p-1 mr-2">
                        <TiArrowRightThick className="text-lg text-blue-500 " />
                      </div>
                      Increased Sales & Revenue
                    </li>
                  </ul>
                </div>

                <div
                  className="grid grid-flow-col grid-cols-2 
                grid-rows-2 gap-4 sm:gap-10 p-3 sm:p-0 md:p-10"
                >
                  <div
                    className="text-black hover:text-white shadow-lg rounded-lg
                  p-5 space-y-2 justify-center items-center flex flex-col bg-purple-100 
                  hover:bg-blue-600 transition duration-700 ease-in-out"
                  >
                    <div className="p-3 rounded-full bg-purple-400 ">
                      <img src={as1} alt="" className="w-10" />
                    </div>
                    <h1 className="font-bold text-base sm:text-xl ">
                      CMS Based
                    </h1>
                  </div>
                  <div
                    className="text-black hover:text-white shadow-lg rounded-lg
        p-5 space-y-2 justify-center items-center flex flex-col bg-green-100
        hover:bg-blue-600 transition duration-700 ease-in-out"
                  >
                    <div className="p-3 rounded-full bg-green-500">
                      <img src={as2} alt="" className="w-10" />
                    </div>
                    <h1 className="font-bold text-base sm:text-xl">
                      OTP Based User Verification
                    </h1>
                  </div>
                  <div
                    className="text-black hover:text-white shadow-lg rounded-lg p-5 space-y-3 justify-center 
        items-center flex flex-col hover:bg-blue-600 bg-yellow-100 transition duration-700 ease-in-out"
                  >
                    <div className="p-3 rounded-full bg-yellow-300">
                      <img src={as3} alt="" className="w-10" />
                    </div>
                    <h1 className="font-bold text-base sm:text-xl">
                      Delivery Slot Selection
                    </h1>
                  </div>
                  <div
                    className="text-black hover:text-white bg-pink-100
                shadow-lg rounded-lg p-5 space-y-4 justify-center 
            items-center flex flex-col hover:bg-blue-600 transition       
            duration-700 ease-in-out"
                  >
                    <div className="p-3 rounded-full bg-pink-500">
                      <img src={as4} alt="" className="w-10" />
                    </div>
                    <h1 className="font-bold text-base sm:text-xl">
                      Payment Gateway Integration
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="py-10 grid grid-cols-1 gap-3 mx-3 lg:mx-20
        lg:grid-cols-2 place-content-around place-items-center lg:py-24"
            >
              <div className="w-full flex justify-center">
                <Fade>
                  <img
                    src={two}
                    alt="image"
                    className="w-72 sm:w-96 md:w-7/12 lg:w-9/12"
                  />
                </Fade>
              </div>
              <div
                className="
           text-center lg:text-left space-y-4"
              >
                <h1
                  className="text-2xl link font-mediums text-black
            font-bold sm:text-4xl lg:text-5xl"
                >
                  IOS <span className="text-blue-600">Application</span>{" "}
                  Development
                </h1>
                <div className="flex flex-col items-center lg:items-start">
                  <div id="loading-bar" className="bg-blue-700 h-1 w-24" />
                </div>
                <h1
                  className="text-sm mx-3 lg:mx-0 sm:text-sm lg:text-base 
            font-medium text-gray-500"
                >
                  BenefitsUsage offers robust and scalable iOS app development
                  services across various platforms and industry verticals. We
                  provide complete end-to-end iOS application development
                  services for mission-critical mobile applications demanding
                  superior performance.
                </h1>
                <ul
                  className="grid grid-flow-col grid-cols-2 grid-rows-2 gap-2
            sm:gap-4 font-semibold text-xs sm:text-base text-gray-500"
                >
                  <li className="flex">
                    <div className="bg-blue-100 rounded-sm p-1 mr-1">
                      <BsCheckBox className="text-lg text-blue-500 " />
                    </div>
                    IPHONE APP STRATEGY
                  </li>
                  <li className="flex">
                    <div className="bg-blue-100 rounded-sm p-1 mr-1">
                      <BsCheckBox className="text-lg text-blue-500 " />
                    </div>
                    IPHONE APP DESIGN
                  </li>
                  <li className="flex">
                    <div className="bg-blue-100 rounded-sm p-1 mr-1">
                      <BsCheckBox className="text-lg text-blue-500 " />
                    </div>
                    IPHONE APP DEVELOPMENT
                  </li>
                  <li className="flex">
                    <div className="bg-blue-100 rounded-sm p-1 mr-1">
                      <BsCheckBox className="text-lg text-blue-500 " />
                    </div>
                    IPHONE APP TESTING
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="bg-blue-50 py-10 lg:py-24 grid grid-cols-1 gap-10 lg:gap-3  lg:mx-0 lg:pl-20
        lg:grid-cols-2 "
            >
              <div
                className="mx-3 
           text-left space-y-6"
              >
                <h1
                  className="text-2xl link font-mediums text-black 
            font-bold sm:text-4xl lg:text-5xl"
                >
                  Mobile App Strategy
                </h1>
                <h1
                  className="text-sm mx-3 lg:mx-0 sm:text-sm lg:text-base 
            font-medium text-gray-500"
                >
                  Apps we build showcase only a portion of what our mobile app
                  development company is capable of. We know what it takes to
                  convert your vision into reality. Having worked on numerous
                  projects, we understand every app project is different and
                  needs special attention. Thus, we spend considerable time in
                  planning and research.
                </h1>
                <ul
                  className="grid grid-rows-1 sm:grid-cols-2 gap-2
            sm:gap-4 font-medium text-sm sm:text-base text-gray-900"
                >
                  <li className="flex">
                    <div className="bg-transparent rounded-sm p-1 mr-2">
                      <TiArrowRightThick className="text-lg text-blue-500 " />
                    </div>
                    Modern Design
                  </li>
                  <li className="flex">
                    <div className="bg-transparent rounded-sm p-1 mr-2">
                      <TiArrowRightThick className="text-lg text-blue-500 " />
                    </div>
                    Creative Idea
                  </li>
                  <li className="flex">
                    <div className="bg-transparent rounded-sm p-1 mr-2">
                      <TiArrowRightThick className="text-lg text-blue-500 " />
                    </div>
                    UI/UX Design
                  </li>
                  <li className="flex">
                    <div className="bg-transparent rounded-sm p-1 mr-2">
                      <TiArrowRightThick className="text-lg text-blue-500 " />
                    </div>
                    Navigation
                  </li>
                  <li className="flex">
                    <div className="bg-transparent rounded-sm p-1 mr-2">
                      <TiArrowRightThick className="text-lg text-blue-500 " />
                    </div>
                    Graphics Design
                  </li>
                  <li className="flex">
                    <div className="bg-transparent rounded-sm p-1 mr-2">
                      <TiArrowRightThick className="text-lg text-blue-500 " />
                    </div>
                    App Features
                  </li>
                </ul>
              </div>
              <div className="w-full flex justify-center lg:justify-start">
                <Fade>
                  {" "}
                  <img src={three} alt="image" className="w-96 lg:w-9/12" />
                </Fade>
              </div>
            </div>
            <div
              className="py-10 grid grid-cols-1 gap-3 mx-3 lg:mx-20
        lg:grid-cols-2 place-content-around place-items-center lg:py-24"
            >
              <div className="w-full flex justify-center">
                <Fade>
                  <img
                    src={four}
                    alt="image"
                    className="w-72 sm:w-96 md:w-7/12 lg:w-9/12"
                  />
                </Fade>
              </div>
              <div
                className="
           text-center lg:text-left space-y-4"
              >
                <h1
                  className="text-2xl link font-mediums text-black
            font-bold sm:text-4xl lg:text-5xl"
                >
                  <span className="text-blue-600">E - Commerce</span>{" "}
                  Application Development
                </h1>
                <div className="flex flex-col items-center lg:items-start">
                  <div id="loading-bar" className="bg-blue-700 h-1 w-24" />
                </div>
                <h1
                  className="text-sm mx-3 lg:mx-0 sm:text-sm lg:text-base 
            font-medium text-gray-500"
                >
                  We offer eCommerce app development on native Android and iOS
                  platforms with responsive designs for smartphones and tablets
                  to help deliver unmatched user experiences across devices and
                  foster brand loyalty. We integrate your eCommerce store with
                  payment gateways such as PayPal and Amazon Payments to develop
                  custom eCommerce payment solutions that accept electronic
                  debit cards, credit cards, mobile payments, and more.
                </h1>
              </div>
            </div>
            <div
              className="py-10 bg-gradient-to-bl from-purple-500 via-purple-300 to-blue-50 
          grid grid-cols-1 gap-3 px-3 lg:px-20
        lg:grid-cols-2 place-content-around place-items-center lg:py-24"
            >
              <div
                className="
           text-center lg:text-left space-y-4"
              >
                <h1
                  className="text-2xl link font-mediums text-black
            font-bold sm:text-4xl lg:text-5xl"
                >
                  Get Custom Mobile Apps For Digital Evolution
                </h1>
                <ul
                  className="grid grid-rows-1 gap-2
            sm:gap-3 font-semibold text-xs sm:text-base text-gray-900"
                >
                  <li className="flex">
                    - Custom Native iOS and Android applications development.
                  </li>
                  <li className="flex">
                    - Cross-platform (Hybrid) Mobile Apps development.
                  </li>
                  <li className="flex">
                    - Mobile Applications Development on Flutter and React
                    Native.
                  </li>
                  <li className="flex">- Social networking app development.</li>
                </ul>
              </div>
              <div className="w-full flex justify-center">
                <Fade>
                  <img src={five} alt="image" className="w-72 sm:w-96 " />
                </Fade>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div className="grid place-content-center place-items-center h-screen">
          <span class="h-10 w-10">
            <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-10 w-10 bg-blue-500">
              <span className="grid place-content-center w-full place-items-center">
                <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
                <span class="inline-flex rounded-full h-5 w-5 bg-blue-600"></span>
              </span>
            </span>
          </span>
        </div>
      )}
    </div>
  );
}
export default AppServiceComponent;
