import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../../css/Banner.css";
import { MdDone, FaArrowRight } from "react-icons/all";
import lottie from "lottie-web";

function NewProject() {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current, // the dom element that will contain the animation
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../../component/new.json"),
    });
  }, []);

  return (
    <div className="py-5">
      <div className="flex flex-col p-10 text-black items-center">
        <h1 className="text-4xl link font-bold m-1 text-center sm:text-6xl">
          Ready to work
          <span className="text-blue-700"> together?</span>
        </h1>
        <h1 className="text-gray-400 text-center font-medium text-sm sm:text-lg mt-4">
          We're ready to help you take your business to the next level. Are you?{" "}
        </h1>
        <div
          className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-5 justify-items-center
         text-black font-medium "
        >
          <div className="flex items-center">
            <div className="bg-blue-500 rounded-full p-2 mx-2">
              <MdDone className="text-white" />
            </div>
            <h1>10 Day Turn-around time</h1>
          </div>
          <div className="flex items-center">
            <div className="bg-blue-500 rounded-full p-2 mx-2">
              <MdDone className="text-white" />
            </div>
            <h1>24/7 Support</h1>
          </div>
          <div className="flex items-center">
            <div className="bg-blue-500 rounded-full p-2 mx-2">
              <MdDone className="text-white" />
            </div>
            <h1>30 min Onboarding</h1>
          </div>
        </div>
        <Link to="/contact">
          <button
            className="bg-blue-900 flex justify-between items-center text-base font-medium 
            cursor-pointer focus:outline-none hover:bg-blue-700  transition duration-700 ease-in-out
           text-center rounded-xl p-5 mt-10 text-white"
          >
            Get Free Consultation
            <FaArrowRight className="ml-2 text-lg animate-bounce" />
          </button>
        </Link>
      </div>
      {/* <div className="flex justify-center">
        <div
          className="rounded-lg grid grid-cols-1 md:grid-cols-2
         items-center w-5/6 bg-gradient-to-br from-gray-800 to-transparent
          text-black"
        >
          <div className="p-10 space-y-2">
            <h1 className="text-white text-3xl sm:text-4xl md:text-5xl font-Sub-Head font-bold mt-1">
              You have a new project
            </h1>
            <h1 className="font-semibold font-Content py-3 text-lg text-gray-300">
              Contact now and get a 20% discount on your new project. We are
              provide quality products.
            </h1>
          </div>
          <div className="container" ref={container}></div>
        </div>
      </div>*/}
    </div>
  );
}
export default NewProject;
