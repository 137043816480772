import React, { useState } from "react";
import Details from "./Details";

function Footer() {
  
  return (
    <div>
      <Details/>
      <div
      className="h-14 w-full bg-black py-9 flex items-center justify-center"
    >
      <div>
        <h1 className="text-white text-center font-medium text-xs md:text-sm">
          &copy; 2023 BenefitsUsage, All rights reserved.
        </h1>
      </div>
    </div>
  
    </div>
    );
}

export default Footer;
