import React from "react";
import { Link } from "react-router-dom";
import { BiMenuAltLeft } from "react-icons/bi";
import { GrFormClose } from "react-icons/gr";
import { BiHomeSmile } from "react-icons/bi";
import { VscSymbolKeyword, VscSymbolMethod } from "react-icons/vsc";
import { GrContactInfo } from "react-icons/gr";
import { AiOutlineInsertRowBelow } from "react-icons/ai";
import Logo from "../../image/benefits.png";
import one from "../../image/mp.png";
import Fade from "react-reveal/Fade";

export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      state: false,
      show: true,
      scrollPos: 0,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    this.setState({
      scrollPos: document.body.getBoundingClientRect().top,
      show: document.body.getBoundingClientRect().top > this.state.scrollPos,
    });
  };

  render() {
    return (
      <div className="bg-banner bg-center bg-scroll">
        <div
          className="h-screen
         w-full bg-blue-50 bg-opacity-90"
        >
          <div>
            {this.state.state === false ? (
              <div
                style={{
                  zIndex: 2,
                  // backgroundColor:
                  //   this.state.scrollPos < -150 ? "#FFF" : "#00000000",
                }}
                className={`flex items-center  w-full
                 fixed sm:mb-0 sm:mx-0 py-2
                 bg-transparent justify-between transition-all ${
                   this.state.scrollPos < -150
                     ? "shadow-md bg-white"
                     : "shadow-none bg-transparent"
                 }`}
              >
                <Link to="/">
                  <img
                    src={Logo}
                    loading="lazy"
                    alt="logo"
                    className="w-48 sm:hidden"
                  />
                </Link>

                <BiMenuAltLeft
                  className="text-4xl cursor-pointer sm:hidden"
                  onClick={() => this.setState({ state: true })}
                />
              </div>
            ) : null}
            {this.state.state === true ? (
              <div
                style={{
                  zIndex: 2,
                }}
                className={`
                fixed bg-white shadow-md w-full sm:hidden`}
              >
                <GrFormClose
                  className="text-4xl cursor-pointer"
                  onClick={() => this.setState({ state: false })}
                />
                <ul className="grid text-xs justify-items-center grid-cols-3 grid-rows-2 gap-10 p-8 text-center">
                  <li>
                    <Link to="">
                      <BiHomeSmile
                        className="text-3xl w-full"
                        onClick={() => this.setState({ state: false })}
                      />
                      <span className="font-semibold text-base">Home</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <VscSymbolKeyword
                        className="text-3xl w-full"
                        onClick={() => this.setState({ state: false })}
                      />
                      <span className="font-semibold text-base">About</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/service">
                      <AiOutlineInsertRowBelow
                        className="text-3xl w-full"
                        onClick={() => this.setState({ state: false })}
                      />
                      <span className="font-semibold text-base">Services</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/product">
                      <VscSymbolMethod
                        className="text-3xl w-full"
                        onClick={() => this.setState({ state: false })}
                      />
                      <span className="font-semibold text-base">Products</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      <GrContactInfo
                        className="text-3xl w-full"
                        onClick={() => this.setState({ state: false })}
                      />
                      <span className="font-semibold text-base">Contact</span>
                    </Link>
                  </li>
                </ul>
              </div>
            ) : null}
            <div
              style={{
                zIndex: 2,
                // backgroundColor:
                //   this.state.scrollPos < -150 ? "#FFF" : "#00000000",
              }}
              className={`hidden fixed w-full sm:flex 
              justify-between md:px-10 items-center transition-all ${
                this.state.scrollPos < -150
                  ? "shadow-md bg-white"
                  : "shadow-none bg-transparent"
              }`}
            >
              <div>
                <div>
                  <Link to="/">
                    <img
                      loading="lazy"
                      src={Logo}
                      alt="logo"
                      className="sm:w-48 sm:ml-5 lg:m-3"
                    />
                  </Link>
                </div>
              </div>
              <div>
                <ul
                  className="flex flex-grow text-black 
              space-x-3 md:space-x-6 p-5 text-sm lg:text-lg font-semibold"
                >
                  <li className="cursor-pointer hover:text-gray-400">
                    <Link to="">
                      <span className="font-semibold text-base">Home</span>
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:text-gray-400">
                    <Link to="/about">
                      <span className="font-semibold text-base">About</span>
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:text-gray-400">
                    <Link to="/service">
                      <span className="font-semibold text-base">Services</span>
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:text-gray-400">
                    <Link to="/product">
                      <span className="font-semibold text-base">Products</span>
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:text-gray-400">
                    <Link to="/contact">
                      <span className="font-semibold text-base">Contact</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div
              className="grid grid-cols-1 py-10 pt-20 md:pt-32 lg:pt-24 md:grid-cols-2 gap-5 lg:gap-0
        place-content-center place-items-center"
            >
              <div className="px-5 md:px-10 lg:px-20 text-left mt-10">
                <ul
                  className="flex flex-row text-gray-700 my-2
             text-sm lg:text-base font-medium space-x-2 lg:space-x-3 px-0 pt-5"
                >
                  <li className="cursor-pointer ">
                    <span className="font-semibold text-base">Better</span>
                  </li>
                  <li className=" w-px bg-gray-400"></li>
                  <li className="cursor-pointer">
                    <span className="font-semibold text-base">Faster</span>
                  </li>
                  <li className=" w-px bg-gray-400"></li>
                  <li className="cursor-pointer ">
                    <span className="font-semibold text-base">Efficient</span>
                  </li>
                </ul>
                <ul
                  className="flex flex-row text-gray-700 mt-3
             text-sm lg:text-base font-medium space-x-1 lg:space-x-2"
                >
                  <li className="cursor-pointer ">
                    <span className="font-semibold text-base">Funnel</span>
                  </li>
                  <li className=" w-px bg-gray-400"></li>
                  <li className="cursor-pointer">
                    <span className="font-semibold text-base">Mobile</span>
                  </li>
                  <li className=" w-px bg-gray-400"></li>
                  <li className="cursor-pointer ">
                    <span className="font-semibold text-base">Web-App</span>
                  </li>
                </ul>{" "}
                <h1 className="text-2xl mt-3 sm:text-3xl lg:text-5xl link font-bold md:leading-9">
                  Make Potential Possible With Us
                </h1>
                <div
                  className="text-sm sm:text-sm mt-3 text-justify
                    lg:text-base font-medium text-gray-500"
                >
                  <h1>
                    Get your Application and Website according to your
                    requirement and also we are experts in Funnel and Landing
                    page creation. We can also promote your business online to
                    get more leads.
                  </h1>
                </div>
              </div>
              <div className="w-full flex justify-center mt-0 md:mt-10">
                <Fade>
                  <img src={one} alt="image" className=" w-72 lg:w-8/12" />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
