import React, { useEffect, useRef } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";

import "../../css/Banner.css";

// import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from "swiper/core";

import Service1 from "../../image/one.jpg";
import Service2 from "../../image/two.jpg";
import Service3 from "../../image/three.png";
import Service4 from "../../image/four.jpg";
import FBads from "../../image/fbads.png";
import Funnel from "../../image/sales.png";
import "../../css/Banner.css";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);

function Services() {
  return (
    <div>
      <section
        style={{ zIndex: 1 }}
        className="w-screen flex py-3 pt-16 justify-center bg-white text-black"
      >
        <div className="flex flex-col text-gray-500 items-center">
          <h1
            className="text-3xl link font-bold text-black
                      lg:text-5xl md:text-4xl mb-3"
          >
            Our Products
          </h1>
          {/*<div className="flex justify-center items-center space-x-10 mt-10">
          <img className="w-2/6 rounded-md" src={Service1} alt=""/>
        <div>
            <h1 className="font-bold text-black text-lg">Morden Website</h1>
            <h1 className="font-medium text-gray-600 text-base">
              Website adaptable to all devices, with ui components and animated interations.
            </h1>
          </div>
        </div>*/}
          <div id="loading-bar" className="bg-blue-700 h-1 w-24"></div>
          <div className="h-auto w-screen max-w-5xl mr-3 sm:mr-5">
            <Swiper
              cssMode={true}
              navigation={true}
              pagination={{ dynamicBullets: true }}
              mousewheel={true}
              keyboard={true}
              className="mySwiper"
            >
              <SwiperSlide>
                <div
                  className="grid grid-cols-1 md:grid-cols-2 
                justify-items-center gap-3 md:gap-0 p-20 md:p-20 
                items-center"
                >
                  <div className="p-2 md:p-10">
                    <img
                      className="w-2/3 rounded-lg shadow-md"
                      src={Service1}
                      alt="Modern-Website"
                    />
                  </div>
                  <div className="grid gap-2">
                    <h1 className="font-bold text-black text-xl ">
                      Modern Website
                    </h1>
                    <h1 className="font-medium text-gray-600 text-justify text-base ">
                      Website adaptable to all devices, with ui components and
                      animated interaction.
                    </h1>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="grid grid-cols-1 md:grid-cols-2 justify-items-center gap-3 md:gap-0 p-16 md:p-20 items-center">
                  <div className="p-2 md:p-10">
                    <img
                      className="w-2/3 rounded-lg shadow-md"
                      src={Funnel}
                      alt="Sales-Funnel"
                    />
                  </div>
                  <div className="grid gap-2">
                    <h1 className="font-bold text-black text-xl ">
                      Sales Funnel
                    </h1>
                    <h1 className="font-medium text-gray-600 text-justify text-base ">
                      We have some of the best Funnel Hackers in the world.
                      Crafting a high converting funnel is our specialty.
                    </h1>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="grid grid-cols-1 md:grid-cols-2 justify-items-center gap-3 md:gap-0 p-16 md:p-20 items-center">
                  <div className="p-2 md:p-10">
                    <img
                      className="w-2/3 rounded-lg shadow-md"
                      src={Service3}
                      alt="Native-Application"
                    />
                  </div>
                  <div className="grid gap-2">
                    <h1 className="font-bold text-black text-xl ">
                      Native Application
                    </h1>
                    <h1 className="font-medium text-gray-600 text-justify text-base ">
                      React Native Application that can run on multiple
                      platforms such as Android, iOS, or Windows. Building
                      cross-platform apps, designing user experience and
                      integrating novel mobile interfaces such as chat and
                      voice.
                    </h1>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="grid grid-cols-1 md:grid-cols-2 justify-items-center gap-3 md:gap-0 p-16 md:p-20 items-center">
                  <div className="p-2 md:p-10">
                    <img
                      className="w-2/3 rounded-lg shadow-md"
                      src={Service2}
                      alt="E-Com"
                    />
                  </div>
                  <div className="grid gap-2">
                    <h1 className="font-bold text-black text-xl ">
                      E-Commerce Website
                    </h1>
                    <h1 className="font-medium text-gray-600 text-justify text-base ">
                      Large scale eCommerce projects. In-depth knowledge of
                      security protocols, secure payment gateways and other
                      safety components applicable for e-trading websites.
                    </h1>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="grid grid-cols-1 md:grid-cols-2 justify-items-center gap-3 md:gap-0 p-16 md:p-20 items-center">
                  <div className="p-2 md:p-10">
                    <img
                      className="w-2/3 rounded-lg shadow-md"
                      src={FBads}
                      alt="Fb-Ads"
                    />
                  </div>
                  <div className="grid gap-2">
                    <h1 className="font-bold text-black text-xl ">
                      Social Media Ads
                    </h1>
                    <h1 className="font-medium text-gray-600 text-justify text-base ">
                      Using some of the less exploited tricks and tactics, we
                      can grow your business pretty fast using social media.
                    </h1>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="grid grid-cols-1 md:grid-cols-2 justify-items-center gap-3 md:gap-0 p-16 md:p-20 items-center">
                  <div className="p-2 md:p-10">
                    <img
                      className="w-2/3 rounded-lg shadow-md"
                      src={Service4}
                      alt="App"
                    />
                  </div>
                  <div className="grid gap-2">
                    <h1 className="font-bold text-black text-xl ">
                      Android Application
                    </h1>
                    <h1 className="font-medium text-gray-600 text-justify text-base ">
                      Specially we provide application for android with core
                      language of JAVA. Those applications support all screen
                      types.
                    </h1>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;
