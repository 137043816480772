import React, { useState } from "react";
import Header from "../main-com/Header";
import Tittle from "../sub-com/Tittle";
import Footer from "../main-com/Footer";
import Video from "../../image/video.png";
import Booking from "../../image/booking.png";
import Chatting from "../../image/chatting.png";
import Mart from "../../image/e-com.png";
import ECom from "../../image/e-commerce.png";
import Track from "../../image/track.png";
import Media from "../../image/media.png";
import Delivery from "../../image/delivery.png";
import Fade from "react-reveal/Fade";
import "../../css/Banner.css";

function ProductsComponent() {
  const [state, setState] = useState(false);

  setInterval(() => {
    setState(true);
  }, 1000);

  return (
   <div className="select-none">
      {state ? (
        <div>
          <Header />
          <Tittle title={"Products"} />
          <h1 className="text-4xl link font-medium px-5 pt-5 mt-3 text-center text-black sm:text-6xl">
            Our <span className="text-blue-700">Products</span>
          </h1>
           <div className="flex flex-col items-center">
            <div id="loading-bar" className="bg-blue-700 h-1 w-24 my-3" />
          </div>
          <div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 
        content-center place-content-center py-12
        mx-5 sm:mx-9 md:mx-12 lg:mx-30 xl:mx-40"
          >
            <div className="justify-center items-center flex flex-col ">
              <Fade>
                <img src={ECom} alt="" className="w-96" />
              </Fade>
              <div
                className="text-black shadow-lg rounded-sm md:row-span-2 lg:row-span-1
        p-10 space-y-2 justify-center flex flex-col 
        hover:text-blue-500 transition duration-700 ease-in-out"
              >
                <h1 className="font-bold text-2xl">Ecommerce Solution</h1>
                <h1 className="text-sm text-gray-400">
                  Our team of developers follows a systematic approach of
                  implementing software deployment testing to ensure that your
                  new eCommerce system provides all functionalities.
                </h1>
              </div>
            </div>
            <div className="justify-center items-center flex flex-col ">
              <Fade>
                <img src={Chatting} alt="" className="w-96" />
              </Fade>
              <div
                className="text-black shadow-lg rounded-sm md:row-span-2 lg:row-span-1
        p-10 space-y-2 justify-center flex flex-col 
        hover:text-blue-500  transition duration-700 ease-in-out"
              >
                <h1 className="font-bold text-2xl">Chatting App</h1>
                <h1 className="text-sm text-gray-400">
                  To get a service of connecting with all around the world and
                  making a video and voice call is possible through chat apps
                  with us.
                </h1>
              </div>
            </div>
            <div className="justify-center items-center flex flex-col ">
              <Fade>
                <img src={Track} alt="" className="w-96" />
              </Fade>
              <div
                className="text-black shadow-lg rounded-sm md:row-span-2 lg:row-span-1
        p-10 space-y-2 justify-center flex flex-col 
        hover:text-blue-500 transition duration-700 ease-in-out"
              >
                <h1 className="font-bold text-2xl">
                  Live Vehicle Tracking App
                </h1>
                <h1 className="text-sm text-gray-400">
                  BenefitsUsage Develop Vehicle Tracking Management App with
                  hyperlink info system.
                </h1>
              </div>
            </div>
            <div className="justify-center items-center flex flex-col ">
              <Fade>
                <img src={Video} alt="" className="w-96" />
              </Fade>
              <div
                className="text-black shadow-lg rounded-sm md:row-span-2 lg:row-span-1
        p-10 space-y-2 justify-center flex flex-col 
        hover:text-blue-500 transition duration-700 ease-in-out"
              >
                <h1 className="font-bold text-2xl">Video Streaming App</h1>
                <h1 className="text-sm text-gray-400">
                  Our Live streaming services allow you to broadcast your audio
                  and video content LIVE in Full HD and in real-time to the
                  world using the Internet.
                </h1>
              </div>
            </div>
            <div className="justify-center items-center flex flex-col ">
              <Fade>
                <img src={Mart} alt="" className="w-96" />
              </Fade>
              <div
                className="text-black shadow-lg rounded-sm md:row-span-2 lg:row-span-1
        p-10 space-y-2 justify-center flex flex-col 
        hover:text-blue-500 transition duration-700 ease-in-out"
              >
                <h1 className="font-bold text-2xl">Grocery Store App</h1>
                <h1 className="text-sm text-gray-400">
                  We have different applications for Customer, Store Manager,
                  Packing Boy & Delivery boy to ease the process of selling.
                </h1>
              </div>
            </div>
            <div className="justify-center items-center flex flex-col ">
              <Fade>
                <img src={Media} alt="" className="w-96" />
              </Fade>
              <div
                className="text-black shadow-lg rounded-sm md:row-span-2 lg:row-span-1
        p-10 space-y-2 justify-center flex flex-col 
        hover:text-blue-500 transition duration-700 ease-in-out"
              >
                <h1 className="font-bold text-2xl">Social Media App</h1>
                <h1 className="text-sm text-gray-400">
                  Create a Strong Social Media Strategy and Campaign plan.
                </h1>
              </div>
            </div>
            <div className="justify-center items-center flex flex-col ">
              <Fade>
                <img src={Delivery} alt="" className="w-96" />
              </Fade>
              <div
                className="text-black shadow-lg rounded-sm md:row-span-2 lg:row-span-1
        p-10 space-y-2 justify-center flex flex-col 
        hover:text-blue-500 transition duration-700 ease-in-out"
              >
                <h1 className="font-bold text-2xl">Food Delivery App</h1>
                <h1 className="text-sm text-gray-400">
                  Allow your customers to order directly from your online food
                  ordering app with multiple integrated payment portals.
                </h1>
              </div>
            </div>
            <div className="justify-center items-center flex flex-col ">
              <Fade>
                <img src={Booking} alt="" className="w-96" />
              </Fade>
              <div
                className="text-black shadow-lg rounded-sm md:row-span-2 lg:row-span-1
        p-10 space-y-2 justify-center flex flex-col 
        hover:text-blue-500 transition duration-700 ease-in-out"
              >
                <h1 className="font-bold text-2xl">Taxi & Cab Booking App</h1>
                <h1 className="text-sm text-gray-400">
                  We are developing Taxi & Cab Booking App with updated
                  information regarding travel destinations.
                </h1>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div className="grid place-content-center place-items-center h-screen">
          <span class="h-10 w-10">
            <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-10 w-10 bg-blue-500">
              <span className="grid place-content-center w-full place-items-center">
                <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
                <span class="inline-flex rounded-full h-5 w-5 bg-blue-600"></span>
              </span>
            </span>
          </span>
        </div>
      )}
    </div>
  );
}
export default ProductsComponent;
