import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import App from "../../image/aservice.png";
import Social from "../../image/mservice.png";
import Web from "../../image/wservice.png";
import Funnel from "../../image/fuservice.png";
import "../../css/Banner.css";

function Service() {
  return (
    <section className="w-full flex bg-white">
      <div className="flex flex-col py-16 text-white items-center">
        <h1
          className="text-3xl link font-bold text-black
                      lg:text-5xl md:text-4xl mb-3"
        >
          Services
        </h1>
        <div id="loading-bar" className="bg-blue-700 h-1 w-24"></div>
        <div
          className="w-full pt-3 grid gap-5 grid-cols-1 text-left 
        md:grid-cols-2 lg:grid-cols-4 mt-16 mx-5 px-5"
        >
          <div className="text-black items-center bg-purple-50 py-6 rounded-md cursor-pointer">
            <div
              className="bg-purple-200 m-3 rounded-full w-10 h-10 items-center 
            flex justify-center font-bold text-sm lg:text-base"
            >
              <h1>01</h1>
            </div>
            <h1 className="text-xl lg:text-2xl font-bold text-black  m-3">
              Funnel Service
            </h1>
            <div className="flex justify-center">
              <Fade>
                <img src={Funnel} alt="" />
              </Fade>
            </div>
            <div className="m-3">
              <h1 className="text-xl lg:text-2xl font-medium text-blue-800  mb-3">
                Sales Funnel Build
              </h1>
              <h1 className="text-base font-medium text-justify text-gray-500">
                We have some of the best Funnel Hackers in the world. Crafting a
                high converting funnel is our specialty.
              </h1>
            </div>
          </div>
          <div className="text-black items-center bg-blue-50 py-6 rounded-md cursor-pointer">
            <div
              className="bg-blue-200 m-3 rounded-full w-10 h-10 items-center 
            flex justify-center font-bold text-sm lg:text-base"
            >
              <h1>02</h1>
            </div>
            <h1 className="text-xl lg:text-2xl font-bold text-black  m-3">
              Web Development
            </h1>
            <div className="flex justify-center">
              <Fade>
                <img src={Web} alt="" />
              </Fade>
            </div>
            <div className="m-3">
              <h1 className="text-xl lg:text-2xl font-medium text-blue-800  mb-3">
                Responsive, Clean & Fast Loading
              </h1>
              <h1 className="text-base font-medium text-justify text-gray-500 ">
                A catchy and better design of a website can increase more user
                traffic on your website.
              </h1>
            </div>
          </div>
          <div className="text-black items-center bg-yellow-50 py-6 rounded-md cursor-pointer">
            <div
              className="bg-yellow-100 m-3 rounded-full w-10 h-10 items-center 
            flex justify-center font-bold text-sm lg:text-base"
            >
              <h1>03</h1>
            </div>
            <h1 className="text-xl lg:text-2xl font-bold text-black  m-3">
              Social Media Marketing
            </h1>
            <div className="flex justify-center">
              <Fade>
                <img src={Social} alt="" />
              </Fade>
            </div>
            <div className="m-3">
              <h1 className="text-xl lg:text-2xl font-medium text-blue-800 mb-3">
                Digital Experience & Branding
              </h1>
              <h1 className="text-base font-medium text-justify text-gray-500 ">
                Social media presence is the best approach to be connected with
                millions of users easily.
              </h1>
            </div>
          </div>
          <div className="text-black items-center bg-pink-50 py-6 rounded-md cursor-pointer">
            <div
              className="bg-pink-200 m-3 rounded-full w-10 h-10 items-center 
            flex justify-center font-bold text-sm lg:text-base"
            >
              <h1>04</h1>
            </div>
            <h1 className="text-xl lg:text-2xl font-bold text-black  m-3">
              App Development
            </h1>
            <div className="flex justify-center">
              <Fade>
                <img src={App} alt="" />
              </Fade>
            </div>
            <div className="m-3">
              <h1 className="lg:text-2xl text-xl font-medium text-blue-800  mb-3">
                UI/UX Design & Mobile App Design
              </h1>
              <h1 className="text-base font-medium text-justify text-gray-500 ">
                We develop easy navigation and user-friendly application with
                effective representation.
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Service;
