import React, { useState } from "react";
import Header from "../main-com/Header";
import Footer from "../main-com/Footer";
import one from "../../image/s-1.png";
import two from "../../image/s-2.jpg";
import three from "../../image/s-3.jpg";
import ws1 from "../../image/ws-1.png";
import ws2 from "../../image/ws-2.png";
import ws3 from "../../image/ws-3.png";
import ws4 from "../../image/ws-4.png";
import { BsCheckBox } from "react-icons/bs";
import Fade from "react-reveal/Fade";
import "../../css/Banner.css";

function MarketingServiceComponent() {
  const [state, setState] = useState(false);

  setInterval(() => {
    setState(true);
  }, 2000);

  return (
   <div className="select-none">
      {state ? (
        <div>
          <Header />
          <div>
            <div
              className="lg:my-0 grid grid-cols-1  py-10 lg:grid-cols-2 gap-5 lg:gap-0
        place-content-center place-items-center lg:h-screen"
            >
              <div className="px-5 md:px-10 mt-12 lg:mt-10 lg:px-14 lg:pl-32 text-center lg:text-left space-y-4">
                <h1 className="text-2xl sm:text-3xl lg:text-5xl link font-bold md:leading-6">
                  Social Media Marketing
                </h1>
                <h1
                  className="text-sm sm:mx-4 md:mx-8 lg:mx-0 sm:text-sm 
            lg:text-base font-medium text-gray-400"
                >
                  Are you looking for an effective way to grow your business’
                  brand awareness, relationships, and website traffic? Then you
                  should be on social media! Get our social media promotion
                  service and build a strong social media presence.
                </h1>
              </div>
              <div className="w-full flex justify-center lg:justify-end">
                <Fade>
                  <img src={one} alt="image" className="w-full pt-10 lg:w-10/12" />
                </Fade>
              </div>
            </div>
            <div className="py--5 lg:py-14">
              <h1
                className="text-2xl link text-center font-medium
               text-black sm:text-4xl lg:text-5xl pb-3"
              >
                Our Approach
              </h1>
              <div className="flex flex-col items-center">
                <div id="loading-bar" className="bg-blue-700 h-1 w-24 mt-3" />
              </div>
              <div
                className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 
        content-center place-content-center text-center py-12
        mx-5 sm:mx-9 md:mx-12 lg:mx-30 xl:mx-40"
              >
                <div
                  className="text-black hover:text-white shadow-lg rounded-lg
        p-5 space-y-2 justify-center items-center flex flex-col bg-purple-100 
        hover:bg-blue-600 transition duration-700 ease-in-out"
                >
                  <div className="p-3 rounded-full border-2 border-dotted border-purple-400 bg-white">
                    <img src={ws1} alt="" className="w-10" />
                  </div>
                  <h1 className="font-bold text-xl ">
                    Understand The Business
                  </h1>
                  <h1 className="text-base m-3">
                    We believe it's very important for any social media agency
                    to understand the client's business and target audience.
                  </h1>
                </div>
                <div
                  className="text-black hover:text-white shadow-lg rounded-lg
        p-5 space-y-2 justify-center items-center flex flex-col bg-green-100
        hover:bg-blue-600 transition duration-700 ease-in-out"
                >
                  <div className="p-3 rounded-full border-2 border-dotted border-green-500 bg-white">
                    <img src={ws2} alt="" className="w-10" />
                  </div>
                  <h1 className="font-bold text-xl">
                    Do Only That's Necessary
                  </h1>
                  <h1 className="text-base m-3">
                    It's not always that every business or celebrity needs the
                    entire gamut of social media.
                  </h1>
                </div>
                <div
                  className="text-black hover:text-white shadow-lg rounded-lg p-5 space-y-3 justify-center 
        items-center flex flex-col hover:bg-blue-600 bg-yellow-100 transition duration-700 ease-in-out"
                >
                  <div className="p-3 rounded-full border-2 border-dotted border-yellow-300 bg-white">
                    <img src={ws3} alt="" className="w-10" />
                  </div>
                  <h1 className="font-bold text-xl">Things Change Overnight</h1>
                  <h1 className="text-base m-3">
                    Without a watchful eye, you can lose ranking on Google, pay
                    a premium on clicks, overspend budgets.
                  </h1>
                </div>
                <div
                  className="text-black hover:text-white bg-pink-100
                shadow-lg rounded-lg p-5 space-y-4 justify-center 
            items-center flex flex-col hover:bg-blue-600 transition       
            duration-700 ease-in-out"
                >
                  <div className="p-3 rounded-full border-2 border-dotted border-pink-500 bg-white">
                    <img src={ws4} alt="" className="w-10" />
                  </div>
                  <h1 className="font-bold text-xl">Be Transparent</h1>
                  <h1 className="text-base m-3">
                    A mantra we follow in a business that's myriad with
                    opaqueness and false claims.
                  </h1>
                </div>
              </div>
            </div>
            <div
              className="py-10 grid grid-cols-1 gap-3 mx-3 lg:mx-20
        lg:grid-cols-2 place-content-around place-items-center lg:py-24"
            >
              <div className="w-full flex justify-center">
                <Fade>
                  <img
                    src={two}
                    alt="image"
                    className="w-72 sm:w-96 md:w-7/12 lg:w-9/12"
                  />
                </Fade>
              </div>
              <div
                className="
           text-center lg:text-left space-y-4"
              >
                <h1
                  className="text-2xl link font-mediums text-black
            font-bold sm:text-4xl lg:text-5xl"
                >
                  We Offer{" "}
                  <span className="text-blue-600">Digital Marketing</span>{" "}
                  Services!
                </h1>
                <div className="flex flex-col items-center lg:items-start">
                  <div id="loading-bar" className="bg-blue-700 h-1 w-24" />
                </div>
                <h1
                  className="text-sm mx-3 lg:mx-0 sm:text-sm lg:text-base 
            font-medium text-gray-500"
                >
                  Whether You’re Looking for New Customers to Fill Your Rooms,
                  Eat-in Your Restaurants, Play on your Golf Courses, or Spend
                  Money on your Products and Services, our Targeted Ad Programs
                  and Social Media Promotions can Do it All!
                </h1>
                <ul
                  className="grid grid-flow-col grid-cols-2 grid-rows-2 gap-2
            sm:gap-4 font-semibold text-xs sm:text-base text-gray-500"
                >
                  <li className="flex">
                    <div className="bg-blue-100 rounded-sm p-1 mr-1">
                      <BsCheckBox className="text-lg text-blue-500 " />
                    </div>
                    Social media Consultant
                  </li>
                  <li className="flex">
                    <div className="bg-blue-100 rounded-sm p-1 mr-1">
                      <BsCheckBox className="text-lg text-blue-500 " />
                    </div>
                    Social media Strategy
                  </li>
                  <li className="flex">
                    <div className="bg-blue-100 rounded-sm p-1 mr-1">
                      <BsCheckBox className="text-lg text-blue-500 " />
                    </div>
                    Social media Optimization
                  </li>
                  <li className="flex">
                    <div className="bg-blue-100 rounded-sm p-1 mr-1">
                      <BsCheckBox className="text-lg text-blue-500 " />
                    </div>
                    Content Development
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="py-10 lg:py-24 grid grid-cols-1 gap-10 lg:gap-3  lg:mx-0 lg:ml-20
        lg:grid-cols-2 "
            >
              <div
                className="mx-3
           text-left space-y-5"
              >
                <h1
                  className="text-2xl link font-mediums text-black 
            font-bold sm:text-4xl lg:text-5xl"
                >
                  Our <span className="text-blue-600"> Services</span>
                </h1>
                <div className="flex flex-col items-start">
                  <div id="loading-bar" className="bg-blue-700 h-1 w-24 -mt-2 md:-mt-0" />
                </div>
                <ul
                  className="grid gap-2 text-left
            sm:gap-4 font-bold text-base sm:text-lg lg:text-xl text-gray-900
            mx-3 sm:mx-6"
                >
                  <li>
                    <div className="flex">
                      <div className="bg-blue-100 rounded-sm p-1 md:p-2 mr-1">
                        <BsCheckBox className="text-lg md:text-xl text-blue-500 " />
                      </div>
                      <h1>Digital Strategy And Planning</h1>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="bg-blue-100 rounded-sm p-1 md:p-2 mr-1">
                        <BsCheckBox className="text-lg md:text-xl text-blue-500 " />
                      </div>
                      <h1>Creative Strategy And Web Graphics Design</h1>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="bg-blue-100 rounded-sm p-1 md:p-2 mr-1">
                        <BsCheckBox className="text-lg md:text-xl text-blue-500 " />
                      </div>
                      <h1>Social Media Marketing</h1>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="bg-blue-100 rounded-sm p-1 md:p-2 mr-1">
                        <BsCheckBox className="text-lg md:text-xl text-blue-500 " />
                      </div>
                      <h1>Videos, GIFs And Content Marketing</h1>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="bg-blue-100 rounded-sm p-1 md:p-2 mr-1">
                        <BsCheckBox className="text-lg md:text-xl text-blue-500 " />
                      </div>
                      <h1>Website Design And Development</h1>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="bg-blue-100 rounded-sm p-1 md:p-2 mr-1">
                        <BsCheckBox className="text-lg md:text-xl text-blue-500 " />
                      </div>
                      <h1>SEO And Search Engine Marketing</h1>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="bg-blue-100 rounded-sm p-1 md:p-2 mr-1">
                        <BsCheckBox className="text-lg md:text-xl text-blue-500 " />
                      </div>
                      <h1>Web Analytics</h1>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="w-full flex justify-center lg:justify-end">
                <Fade>
                  <img src={three} alt="image" className="w-full lg:w-full" />
                </Fade>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div className="grid place-content-center place-items-center h-screen">
          <span class="h-10 w-10">
            <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-10 w-10 bg-blue-500">
              <span className="grid place-content-center w-full place-items-center">
                <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
                <span class="inline-flex rounded-full h-5 w-5 bg-blue-600"></span>
              </span>
            </span>
          </span>
        </div>
      )}
    </div>
  );
}
export default MarketingServiceComponent;
