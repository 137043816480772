import React, { useState } from "react";
import About from "../main-com/About";
import Footer from "../main-com/Footer";
import Service from "../main-com/Service";
import Services from "../main-com/Services";
import FAQuestion from "../main-com/FAQuestion";
import NewProject from "../main-com/NewProject";
import Intro from "../main-com/Intro";
import HowWorks from "../main-com/HowWorks";

function MainPage() {
  const [bg, setbg] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setbg(true);
    } else {
      setbg(false);
    }
  };
  window.addEventListener("scroll", changeBackground);

  const [state, setState] = useState(false);

  setInterval(() => {
    setState(true);
  }, 2000);

  return (
    <div className="select-none">
      {state ? (
        <div style={{ overflow: "hidden" }}>
          <Intro />
          {/* <About /> */}
          <Service />
          <HowWorks />
          <Services />
          <FAQuestion />
          <NewProject />
          <Footer />
        </div>
      ) : (
        <div className="grid place-content-center place-items-center h-screen">
          <span class="h-10 w-10">
            <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-10 w-10 bg-blue-500">
              <span className="grid place-content-center w-full place-items-center">
                <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
                <span class="inline-flex rounded-full h-5 w-5 bg-blue-600"></span>
              </span>
            </span>
          </span>
        </div>
      )}
    </div>
  );
}

export default MainPage;
