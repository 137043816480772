import React from "react";
import { HiOutlineMail } from "react-icons/hi";
import { IoLocationOutline } from "react-icons/io5";
import { FiPhoneCall } from "react-icons/fi";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";

function Details() {
  return (
    <div className="bg-black pt-10 px-2">
      <div
        className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 
                space-y-10 xl:space-y-0 mx-4 md:mx-0 
                place-content-start place-items-start md:place-items-center"
      >
        <div className="text-white">
          <h1 className="text-2xl font-medium">CONTACT US</h1>
          <div className="space-y-4 px-5 pt-5 sm:hidden">
            <div className="font-medium flex cursor-pointer items-center">
              <HiOutlineMail className="text-2xl mx-2" />
              <div>
                <h1 className="text-base">Email</h1>
                <a
                  href="mailto:contact@benefitsusage.com"
                  className="text-xs text-gray-400"
                >
                  contact@benefitsusage.com
                </a>
              </div>
            </div>
            <div className="font-medium flex cursor-pointer items-center">
              <FiPhoneCall className="text-2xl mx-2" />
              <div>
                <h1 className="text-base">Phone</h1>
                <a href="tel:+91 9894974130" className="text-sm text-gray-400">
                  +91-9894974130
                </a>
              </div>
            </div>
            <div className="font-medium flex items-center">
              <IoLocationOutline className="text-2xl mx-2" />
              <div>
                <h1 className="text-base">Address</h1>
                <h1 className="text-sm text-gray-400">
                  Tiruppur-641602,Tamil Nadu, India.
                </h1>
              </div>
            </div>
          </div>
          <div className="space-y-4 px-5 pt-5 hidden sm:flex flex-col">
            <div className="font-medium flex cursor-pointer items-center">
              <HiOutlineMail className="text-2xl mx-2" />
              <div className="flex text-center items-center">
                <h1 className="text-base ">Email :</h1>
                <a
                  href="mailto:contact@benefitsusage.com"
                  className="text-sm text-gray-400 ml-2"
                >
                  contact@benefitsusage.com
                </a>
              </div>
            </div>
            <div className="font-medium flex cursor-pointer items-center">
              <FiPhoneCall className="text-2xl mx-2" />
              <div className="flex text-center items-center">
                <h1 className="text-base ">Phone :</h1>
                <a
                  href="tel:+91 9894974130"
                  className="text-sm text-gray-400 ml-2"
                >
                  +91-9894974130
                </a>
              </div>
            </div>
            <div className="font-medium flex items-center">
              <IoLocationOutline className="text-2xl mx-2" />
              <div className="flex text-center items-center">
                <h1 className="text-base">Address :</h1>
                <h1 className="text-sm text-gray-400 ml-2">
                  Tiruppur-641602,Tamil Nadu, India.
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="text-white">
          <h1 className="text-2xl font-medium">FOLLOW US</h1>
          <div className="flex space-x-4 px-5 pt-5">
            <a
              href="https://www.facebook.com/BenefitsUsage/?ref=pages_you_manage"
              target="_blank"
              rel="noreferrer"
            >
              <div className="bg-blue-500 rounded-full p-3">
                <FaFacebookF />
              </div>
            </a>
            <a
              href="https://www.instagram.com/benefitsusage/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="bg-red-700 rounded-full p-3">
                <AiOutlineInstagram className="text-lg" />
              </div>
            </a>
            <a
              href="https://www.linkedin.com/company/benefitsusage/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="bg-blue-800 rounded-full p-3">
                <FaLinkedinIn />
              </div>
            </a>
          </div>
        </div>
        <div className="text-white md:col-span-2 xl:col-span-1">
          <h1 className="text-2xl font-medium">QUICK LINKS</h1>
          <ul
            className="flex flex-col md:flex-row text-white
             text-sm lg:text-base font-medium
          space-y-1 md:space-y-0 md:space-x-2
          lg:space-x-3 px-0 pt-5"
          >
            <li className="cursor-pointer hover:text-gray-400">
              <Link to="">
                <span className="font-semibold text-base">Home</span>
              </Link>
            </li>
            <li className=" w-px bg-white"></li>
            <li className="cursor-pointer hover:text-gray-400">
              <Link to="/about">
                <span className="font-semibold text-base">About</span>
              </Link>
            </li>
            <li className=" w-px bg-white"></li>
            <li className="cursor-pointer hover:text-gray-400">
              <Link to="/service">
                <span className="font-semibold text-base">Services</span>
              </Link>
            </li>
            <li className=" w-px bg-white"></li>
            <li className="cursor-pointer hover:text-gray-400">
              <Link to="/product">
                <span className="font-semibold text-base">Products</span>
              </Link>
            </li>
            <li className=" w-px bg-white"></li>
            <li className="cursor-pointer hover:text-gray-400">
              <Link to="/contact">
                <span className="font-semibold text-base">Contact</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Details;
