import React, { useState } from "react";
import { Tab } from "@headlessui/react";
import Header from "../main-com/Header";
import Tittle from "../sub-com/Tittle";
import Footer from "../main-com/Footer";
import About from "../../image/about.jpg";
import Fade from "react-reveal/Fade";
import "../../css/Banner.css";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AboutComponent() {
  let [categories] = useState({
    Philosophy: [
      {
        id: 1,
        heading: "How To Generate Your Creative Idea With IT Business",
        l1: " Understand Philosopy of Business Analytics",
        l2: " A Better Platform Creation for Your users",
        l3: "Creating Ultimate Deals for your Business",
        l4: "Fast Track Your Business",
        l5: "Stable your Clients need at any cost",
      },
    ],
    Mission: [
      {
        id: 1,
        title:
          "To combine our unique infrastructure and technological capabilities with the expertise and knowledge of our employees to not only successfully design, develop and implement projects of any size but also to augment product development efficiency and responsiveness.",
      },
    ],
    Vision: [
      {
        id: 1,
        title:
          "With our innovative ideas and creative thinking we want to meet every goal that can help a client to enhance their services and productivity in an effective and prosperous way. We don't want to achieve a goal but we want to set a new standard in the market that can lead everyone to achieve certain goals. Setting new standards can help others to have an eye on your achieved target. It will help them to light a fire in themself to enhance their capability and they can push their efforts towards their own standards in their life.",
      },
    ],
  });

  const [state, setState] = useState(false);

  setInterval(() => {
    setState(true);
  }, 1000);

  return (
    <div className="select-none">
      {state ? (
        <div>
          <Header />
          <Tittle title={"About"} />
          <div
            className="grid grid-cols-1 lg:grid-cols-2 gap-10 
        content-center place-content-center"
          >
            <div>
              <Fade>
                <img src={About} alt="" className="hidden lg:flex border-t-2" />
              </Fade>
            </div>
            <div
              className="text-black mx-5
        py-10 space-y-2 justify-center flex flex-col "
            >
              <h1
                className="text-2xl link font-mediums text-gray-600
               font-bold sm:text-4xl lg:text-5xl pb-2"
              >
                Welcome <span className="text-blue-700">BenefitsUsage</span>
              </h1>
              <div id="loading-bar" className="bg-blue-700 h-1 w-24"></div>
              <h10 className="text-base lg:text-lg md:mr-5 text-justify text-gray-600">
                We're a group of developers and marketers who are just targeting
                to convert every idea into a workable form with new technologies
                and effective ways. We do everything customized according to
                your need. Our focus is to understand the requirement of clients
                and deliver what they want.
              </h10>
              <h1 className="text-base lg:text-lg md:mr-5 text-justify text-gray-600">
                Our main focus is on developing mobile application and
                softwares. Along with this we are focusing on our internship
                program, by which we can train freshers to adopt and learn new
                technologies. Currently, we focusing on the creation of our own
                products that can reach in the market to a large audience and
                can serve them according to their need.
              </h1>
              <Tab.Group>
                <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl">
                  {Object.keys(categories).map((category) => (
                    <Tab
                      key={category}
                      className={({ selected }) =>
                        classNames(
                          "w-full py-2.5 text-sm sm:text-lg leading-5 font-medium text-blue-900 rounded-lg focus:outline-none ",
                          "ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60",
                          selected
                            ? "bg-white font-bold underline outline-none"
                            : "text-blue-100 hover:bg-white/[0.12] hover:text-blue-500"
                        )
                      }
                    >
                      {category}
                    </Tab>
                  ))}
                </Tab.List>
                <Tab.Panels className="mt-0">
                  {Object.values(categories).map((posts, idx) => (
                    <Tab.Panel
                      key={idx}
                      className={classNames(
                        "bg-white rounded-xl p-3",
                        "focus:outline-none ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60"
                      )}
                    >
                      <ul>
                        {posts.map((post) => (
                          <li
                            key={post.id}
                            className="relative p-3 rounded-md hover:bg-coolGray-100"
                          >
                            <h3 className="text-xl font-bold text-justify leading-5">
                              {post.heading}
                            </h3>
                            <h3 className="text-base font-medium text-justify leading-6 text-gray-600">
                              {post.title}
                            </h3>

                            {post.l1 ? (
                              <ul className="mt-3 space-y-3 text-justify text-base font-normal leading-4 text-coolGray-500">
                                <li>&middot; {post.l1}</li>
                                <li>&middot; {post.l2}</li>
                                <li>&middot; {post.l3}</li>
                                <li>&middot; {post.l4}</li>
                                <li>&middot; {post.l5}</li>
                              </ul>
                            ) : null}
                          </li>
                        ))}
                      </ul>
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div className="grid place-content-center place-items-center h-screen">
          <span class="h-10 w-10">
            <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-10 w-10 bg-blue-500">
              <span className="grid place-content-center w-full place-items-center">
                <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
                <span class="inline-flex rounded-full h-5 w-5 bg-blue-600"></span>
              </span>
            </span>
          </span>
        </div>
      )}
    </div>
  );
}
