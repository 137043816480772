import React, { useState } from "react";
import Header from "../main-com/Header";
import Footer from "../main-com/Footer";
import one from "../../image/w-1.png";
import two from "../../image/w-2.png";
import three from "../../image/w-3.jpg";
import four from "../../image/w-4.jpg";
import ws1 from "../../image/ws-1.png";
import ws2 from "../../image/ws-2.png";
import ws3 from "../../image/ws-3.png";
import ws4 from "../../image/ws-4.png";
import { BsCheckBox } from "react-icons/bs";
import Fade from "react-reveal/Fade";
import "../../css/Banner.css";

function WebServiceComponent() {
  const [state, setState] = useState(false);

  setInterval(() => {
    setState(true);
  }, 2000);

  return (
    <div className="select-none">
      {state ? (
        <div>
          <Header />
          <div>
            <div
              className="lg:my-0 grid grid-cols-1 lg:grid-cols-2 
        place-content-center place-items-center h-screen"
            >
              <div className="px-5 md:px-10 md:mt-10 lg:px-14 lg:pl-32 text-center lg:text-left space-y-4">
                <h1 className="text-2xl sm:text-3xl lg:text-5xl link font-bold md:leading-6">
                  WEB DEVELOPMENT
                </h1>
                <h1
                  className="text-sm sm:mx-4 md:mx-8 lg:mx-0 sm:text-sm 
            lg:text-base font-medium text-gray-400"
                >
                  Leading Website Designer and Developer in India It's the art
                  of shaping your dreams into an authoritative design or website
                  that will serve as a bridge.
                </h1>
              </div>
              <div className="w-full flex justify-center lg:justify-start">
                <Fade>
                  <img
                    src={one}
                    alt="image"
                    className="w-72 sm:w-96 md:w-6/12 lg:w-10/12"
                  />
                </Fade>
              </div>
            </div>
            <div>
              <h1
                className="text-2xl link text-center font-medium 
              text-black sm:text-4xl lg:text-5xl pb-4"
              >
                Services We Offer
              </h1>
              <div className="flex flex-col items-center">
                <div id="loading-bar" className="bg-blue-700 h-1 w-24" />
              </div>
              <div
                className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 
        content-center place-content-center text-center pb-12 pt-8
        mx-5 sm:mx-9 md:mx-12 lg:mx-30 xl:mx-40"
              >
                <div
                  className="text-black hover:text-white shadow-lg rounded-lg
        p-5 space-y-2 justify-center items-center flex flex-col bg-purple-100 
        hover:bg-blue-600 transition duration-700 ease-in-out"
                >
                  <div className="p-3 rounded-full border-2 border-dotted border-purple-400 bg-white">
                    <img src={ws1} alt="" className="w-10" />
                  </div>
                  <h1 className="font-bold text-xl ">CMS Development</h1>
                  <h1 className="text-base m-3">
                    If you need a customized CMS for your site, then we can help
                    you with that. Let us know what features you want and need
                    and we can make it happen for you and your site.
                  </h1>
                </div>
                <div
                  className="text-black hover:text-white shadow-lg rounded-lg
        p-5 space-y-2 justify-center items-center flex flex-col bg-green-100
        hover:bg-blue-600 transition duration-700 ease-in-out"
                >
                  <div className="p-3 rounded-full border-2 border-dotted border-green-500 bg-white">
                    <img src={ws2} alt="" className="w-10" />
                  </div>
                  <h1 className="font-bold text-xl">
                    Responsive Web Development
                  </h1>
                  <h1 className="text-base m-3">
                    As a web solution company, we provide website designing
                    starting from planning, creation to updating & maintenance
                    of the websites.
                  </h1>
                </div>
                <div
                  className="text-black hover:text-white shadow-lg rounded-lg p-5 space-y-3 justify-center 
        items-center flex flex-col hover:bg-blue-600 bg-yellow-100 transition duration-700 ease-in-out"
                >
                  <div className="p-3 rounded-full border-2 border-dotted border-yellow-300 bg-white">
                    <img src={ws3} alt="" className="w-10" />
                  </div>
                  <h1 className="font-bold text-xl">
                    E-Commerce Website Development
                  </h1>
                  <h1 className="text-base m-3">
                    BenefitsUsage goes with the flow and understands the diverse
                    needs of the clients, and offers them impressive visuals of
                    the platform.
                  </h1>
                </div>
                <div
                  className="text-black hover:text-white bg-pink-100
                shadow-lg rounded-lg p-5 space-y-4 justify-center 
            items-center flex flex-col hover:bg-blue-600 transition       
            duration-700 ease-in-out"
                >
                  <div className="p-3 rounded-full border-2 border-dotted border-pink-500 bg-white">
                    <img src={ws4} alt="" className="w-10" />
                  </div>
                  <h1 className="font-bold text-xl">Maintenance & Support</h1>
                  <h1 className="text-base m-3">
                    We offer end-to-end support and services of maintenance so
                    that there come no circumstances that hinder the seamless
                    working of the online transactions.
                  </h1>
                </div>
              </div>
            </div>
            <div
              className="py-10 lg:py-5 bg-blue-50 grid grid-cols-1 
        lg:grid-cols-2 place-content-center place-items-center"
            >
              <div
                className="px-10 md:px-10
           lg:p-10 text-center lg:text-left space-y-4"
              >
                <h1 className="text-2xl sm:text-3xl lg:text-5xl link font-bold">
                  Awesome Things About Design
                </h1>
                <h1
                  className="text-sm sm:mx-4 md:mx-8 lg:mx-0 sm:text-sm 
            lg:text-base font-medium text-gray-500"
                >
                  Leading Website Designer and Developer in India It's the art
                  of shaping your dreams into an authoritative design or website
                  that will serve as a bridge between your business objectives
                  and your target audience. It conveys your true moral and
                  ethical values and thoughts that revolve around your entire
                  business prodigy. At BenefitsUsage, we acknowledge that every
                  single website, be it static or dynamic, responsive or an
                  e-commerce, how crucial it is to design and develop it in the
                  most interactive and efficient corporate website for your
                  business. We are certainly the trusted partners you have been
                  waiting for website designing and development, as we have
                  achieved this proficiently, over and over, time and again.
                </h1>
              </div>
              <div className="w-full flex justify-center lg:justify-start">
                <Fade>
                  <img
                    src={two}
                    alt="image"
                    className="w-72 sm:w-96 md:w-6/12 lg:w-10/12"
                  />
                </Fade>
              </div>
            </div>
            <div
              className="py-10 grid grid-cols-1 gap-3 mx-3 lg:mx-20
        lg:grid-cols-2 place-content-around place-items-center lg:py-24"
            >
              <div className="w-full flex justify-center">
                <Fade>
                  <img
                    src={three}
                    alt="image"
                    className="w-72 sm:w-96 md:w-7/12 lg:w-9/12"
                  />
                </Fade>
              </div>
              <div
                className="
           text-center lg:text-left space-y-4"
              >
                <h1
                  className="text-2xl link font-mediums text-black 
            font-bold sm:text-4xl lg:text-5xl"
                >
                  Web <span className="text-blue-600">Designing</span>{" "}
                  Technologies
                </h1>
                <div className="flex flex-col items-center lg:items-start">
                  <div id="loading-bar" className="bg-blue-700 h-1 w-24" />
                </div>
                <h1
                  className="text-sm mx-3 lg:mx-0 sm:text-sm lg:text-base 
            font-medium text-gray-500"
                >
                  With more and more designers paying greater attention to UX/UI
                  designs, there is a greater opportunity for developers to
                  include the benefits of the latest website design trend 2023.
                  UI design, being a part of overall UX, offers the techniques
                  to provide a better experience to the users browsing through
                  the websites.
                </h1>
                <ul
                  className="grid grid-flow-col grid-cols-3 grid-rows-3 gap-2
            sm:gap-4 font-semibold text-xs sm:text-base text-gray-500"
                >
                  <li className="flex">
                    <div className="bg-blue-100 rounded-sm p-1 mr-1">
                      <BsCheckBox className="text-lg text-blue-500 " />
                    </div>
                    HTML5
                  </li>
                  <li className="flex">
                    <div className="bg-blue-100 rounded-sm p-1 mr-1">
                      <BsCheckBox className="text-lg text-blue-500 " />
                    </div>
                    CSS3
                  </li>
                  <li className="flex">
                    <div className="bg-blue-100 rounded-sm p-1 mr-1">
                      <BsCheckBox className="text-lg text-blue-500 " />
                    </div>
                    SASS
                  </li>
                  <li className="flex">
                    <div className="bg-blue-100 rounded-sm p-1 mr-1">
                      <BsCheckBox className="text-lg text-blue-500 " />
                    </div>
                    JAVASCRIPT
                  </li>
                  <li className="flex">
                    <div className="bg-blue-100 rounded-sm p-1 mr-1">
                      <BsCheckBox className="text-lg text-blue-500 " />
                    </div>
                    TAILWIND
                  </li>
                  <li className="flex">
                    <div className="bg-blue-100 rounded-sm p-1 mr-1">
                      <BsCheckBox className="text-lg text-blue-500 " />
                    </div>
                    BOOTSTRAP 5
                  </li>
                  <li className="flex">
                    <div className="bg-blue-100 rounded-sm p-1 mr-1">
                      <BsCheckBox className="text-lg text-blue-500 " />
                    </div>
                    ANGULAR JS
                  </li>
                  <li className="flex">
                    <div className="bg-blue-100 rounded-sm p-1 mr-1">
                      <BsCheckBox className="text-lg text-blue-500 " />
                    </div>
                    REACT JS
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="py-10 lg:py-24 grid grid-cols-1 gap-3 mx-3 lg:mx-0 lg:ml-20
        lg:grid-cols-2 place-content-around place-items-center"
            >
              <div
                className="
           text-center lg:text-left space-y-4"
              >
                <h1
                  className="text-2xl link font-mediums text-black
            font-bold sm:text-4xl lg:text-5xl"
                >
                  Web <span className="text-blue-600">Development</span>{" "}
                  Services
                </h1>
                <div className="flex flex-col items-center lg:items-start">
                  <div id="loading-bar" className="bg-blue-700 h-1 w-24 mb-1" />
                </div>
                <ul
                  className="grid grid-flow-col grid-cols-1 grid-rows-3 gap-2 text-left
            sm:gap-4 font-bold text-base sm:text-lg lg:text-xl text-gray-500
            mx-3 sm:mx-6"
                >
                  <li>
                    <div className="flex">
                      <div className="bg-blue-100 rounded-sm p-1 md:p-2 mr-1">
                        <BsCheckBox className="text-lg md:text-xl text-blue-500 " />
                      </div>
                      <h1>Web Development And Web Design</h1>
                    </div>
                    <div className="flex">
                      <div className="bg-transparent rounded-sm p-1 md:p-3 mr-1">
                        <BsCheckBox className="text-lg md:text-xl text-transparent " />
                      </div>
                      <h1 className="text-sm lg:text-base font-medium">
                        We are a very focused team of experts with a deep
                        understanding of web and design we. Believes in creating
                        meaning full web application with user experiences at
                        the heart.
                      </h1>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="bg-blue-100 rounded-sm p-1 md:p-2 mr-1">
                        <BsCheckBox className="text-lg md:text-xl text-blue-500 " />
                      </div>
                      <h1>WordPress Development</h1>
                    </div>
                    <div className="flex">
                      <div className="bg-transparent rounded-sm p-1 md:p-3 mr-1">
                        <BsCheckBox className="text-lg md:text-xl text-transparent " />
                      </div>
                      <h1 className="text-sm lg:text-base font-medium">
                        With over 50 highly optimized WordPress websites in our
                        portfolio website offers a fully customized
                        cost-efficient experience for clients looking to build.
                      </h1>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <div className="bg-blue-100 rounded-sm p-1 md:p-2 mr-1">
                        <BsCheckBox className="text-lg md:text-xl text-blue-500 " />
                      </div>
                      <h1>Magento Development Services</h1>
                    </div>
                    <div className="flex">
                      <div className="bg-transparent rounded-sm p-1 md:p-3 mr-1">
                        <BsCheckBox className="text-lg md:text-xl text-transparent " />
                      </div>
                      <h1 className="text-sm lg:text-base font-medium">
                        We provide Magento development services by creating a
                        versatile Magento store for your niche market.
                      </h1>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="w-full flex justify-center lg:justify-end">
                <Fade>
                  <img
                    src={four}
                    alt="image"
                    className="w-72 sm:w-96 md:w-6/12 lg:w-full"
                  />
                </Fade>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div className="grid place-content-center place-items-center h-screen">
          <span class="h-10 w-10">
            <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-10 w-10 bg-blue-500">
              <span className="grid place-content-center w-full place-items-center">
                <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
                <span class="inline-flex rounded-full h-5 w-5 bg-blue-600"></span>
              </span>
            </span>
          </span>
        </div>
      )}
    </div>
  );
}
export default WebServiceComponent;
