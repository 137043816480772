import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../css/Banner.css";
import FAQ from "../../image/faq.png";
import { HiOutlinePlusSm } from "react-icons/hi";
import { GrFormClose } from "react-icons/gr";
import Zoom from "react-reveal/Zoom";

function Qualification() {
  const [front, setfront] = useState(false);
  const [back, setback] = useState(false);
  const [other, setother] = useState(false);
  return (
    <section className="w-full flex bg-black justify-center text-white">
      <div
        className="w-full grid gap-5 grid-cols-1 md:grid-cols-2 max-w-6xl
       mt-5 place-items-center"
      >
        <div className="mx-7">
          <div className="py-10">
            <h1
              className="text-xl link font-Heading font-bold m-0 text-green-400
                    md:text-3xl sm:text-2xl"
            >
              FAQ
            </h1>
            <h1 className="text-white text-3xl font-Sub-Head sm:text-3xl md:text-4xl font-bold mt-1">
              Most Asked Questions
            </h1>
          </div>
          <div className="flex h-auto flex-col justify-between text-gray-300">
            <div className="pb-5">
              <div
                className=" flex items-center cursor-pointer"
                onClick={() => (front ? setfront(false) : setfront(true))}
              >
                {front ? (
                  <div className="bg-blue-500 rounded-md p-1">
                    <GrFormClose className="text-xl font-bold" />
                  </div>
                ) : (
                  <div className="bg-blue-500 rounded-md p-1">
                    <HiOutlinePlusSm className="text-xl text-black" />
                  </div>
                )}
                <div className="ml-3">
                  <h1 className="text-xl font-mono">How much it costs?</h1>
                </div>
              </div>
              {front ? (
                <div className=" mt-3 mx-10">
                  <h1 className="text-lg font-medium text-justify ">
                    Most of our projects range anywhere between $1500 to
                    $10,000. Depending on your needs and the time involved, the
                    rates will vary.
                  </h1>
                </div>
              ) : null}
              <div className="h-px bg-gray-400 mt-5"></div>
            </div>
            <div className="pb-5">
              <div
                className=" flex items-center cursor-pointer"
                onClick={() => (back ? setback(false) : setback(true))}
              >
                {back ? (
                  <div className="bg-blue-500 rounded-md p-1">
                    <GrFormClose className="text-xl font-bold" />
                  </div>
                ) : (
                  <div className="bg-blue-500 rounded-md p-1">
                    <HiOutlinePlusSm className="text-xl text-black" />
                  </div>
                )}
                <div className="ml-5">
                  <h1 className="text-xl font-mono">
                    What's the turn-around time?
                  </h1>
                </div>
              </div>
              {back ? (
                <div className=" mt-3 mx-10">
                  <h1 className="text-lg font-medium text-justify">
                    Turn around time depends on the complexity of the project.
                    Tasks like Re-design takes 7 days from onboarding, whereas
                    building a complete website or a Funnel ecosystem takes
                    anywhere between 2-3 weeks.
                  </h1>
                </div>
              ) : null}
              <div className="h-px bg-gray-400 mt-5"></div>
            </div>
            <div className="pb-5">
              <div
                className=" flex items-center cursor-pointer"
                onClick={() => (other ? setother(false) : setother(true))}
              >
                {other ? (
                  <div className="bg-blue-500 rounded-md p-1">
                    <GrFormClose className="text-xl font-bold" />
                  </div>
                ) : (
                  <div className="bg-blue-500 rounded-md p-1">
                    <HiOutlinePlusSm className="text-xl text-black " />
                  </div>
                )}
                <div className="ml-5">
                  <h1 className="text-xl font-mono">
                    What results are guaranteed?
                  </h1>
                </div>
              </div>
              {other ? (
                <div className="mt-3 mx-10">
                  <h1 className="text-lg font-medium text-justify">
                    We only guarantee results when we have control over the
                    entire marketing aspect. It includes ads, funnels, websites,
                    etc. If we are the one doing all those stuff for your
                    business, we Do Guarantee results.
                  </h1>
                </div>
              ) : null}
              <div className="h-px bg-gray-400 mt-5"></div>
            </div>
          </div>
        </div>
        <div>
          <Zoom right>
            <img className="w-96" src={FAQ} alt="FAQ" />
          </Zoom>
        </div>
      </div>
    </section>
  );
}

export default Qualification;
