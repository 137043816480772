import "./App.css";
import MainPage from "./component/screen/MainPage";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import AboutComponent from "./component/screen/AboutComponent";
import ServiceComponent from "./component/screen/ServiceComponent";
import ProductComponent from "./component/screen/ProductsComponent";
import ContactComponent from "./component/screen/ContactComponent";
import WebServiceComponent from "./component/screen/WebServiceComponent";
import AppServiceComponent from "./component/screen/AppServiceComponent";
import FunnelServiceComponent from "./component/screen/FunnelServiceComponent";
import MarketingServiceComponent from "./component/screen/MarketingServiceComponent"

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={MainPage} />
        <Route path="/about" component={AboutComponent} />
        <Route path="/service" component={ServiceComponent} />
        <Route path="/product" component={ProductComponent} />
        <Route path="/contact" component={ContactComponent} />
        <Route path="/website-service" component={WebServiceComponent} />
        <Route path="/application-service" component={AppServiceComponent} />
        <Route path="/funnel-service" component={FunnelServiceComponent} />
        <Route
          path="/marketing-service"
          component={MarketingServiceComponent}
        />
      </Switch>
    </Router>
  );
}

export default App;
