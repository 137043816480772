import React, { useState } from "react";
import Header from "../main-com/Header";
import Footer from "../main-com/Footer";
import one from "../../image/f-1.png";
import two from "../../image/f-2.png";
import three from "../../image/f-4.png";
import ws1 from "../../image/fs-1.png";
import ws2 from "../../image/ws-2.png";
import ws3 from "../../image/fs-3.png";
import ws4 from "../../image/fs-4.png";
import ft1 from "../../image/ft-1.png";
import ft2 from "../../image/ft-2.png";
import ft3 from "../../image/ft-3.png";
import ft4 from "../../image/ft-4.png";
import ft5 from "../../image/ft-5.png";
import Fade from "react-reveal/Fade";
import "../../css/Banner.css";

function FunnelServiceComponent() {
  const [state, setState] = useState(false);

  setInterval(() => {
    setState(true);
  }, 2000);

  return (
  <div className="select-none">
      {state ? (
        <div>
          <Header />
          <div>
            <div
              className="lg:my-0 grid grid-cols-1 lg:grid-cols-2
               gap-5 lg:gap-0 place-content-center place-items-center h-screen"
            >
              <div className="px-5 md:px-10 lg:px-14 lg:pl-32 text-center lg:text-left space-y-4">
                <h1 className="text-2xl sm:text-3xl lg:text-5xl link font-bold md:leading-6">
                  SALES FUNNEL
                </h1>
                <h1
                  className="text-sm sm:mx-4 md:mx-8 lg:mx-0 sm:text-sm text-justify 
            lg:text-base font-medium text-gray-400"
                >
                  The marketing funnel is a way to visualize the process of
                  turning leads into customers. ... As you move down the funnel,
                  it becomes narrower and the efforts of your marketing become
                  more focused, targeting the most qualified audience with the
                  highest propensity to convert.
                </h1>
              </div>
              <div className="w-full px-20 flex justify-center lg:justify-end">
                <Fade>
                  <img
                    src={one}
                    alt="image"
                    className="w-80 sm:w-96 md:w-6/12 lg:w-11/12"
                  />
                </Fade>
              </div>
            </div>
            <div>
              <h1
                className="text-2xl link text-center font-medium
               text-black sm:text-4xl lg:text-5xl"
              >
                Services We Offer
              </h1>
              <div className="flex flex-col items-center">
                <div id="loading-bar" className="bg-blue-700 h-1 w-24 mt-3" />
              </div>
              <div
                className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 
        content-center place-content-center text-center py-12
        mx-5 sm:mx-9 md:mx-12 lg:mx-30 xl:mx-40"
              >
                <div
                  className="text-black hover:text-white shadow-lg rounded-lg
        p-5 space-y-2 justify-center items-center flex flex-col bg-purple-100 
        hover:bg-blue-600 transition duration-700 ease-in-out"
                >
                  <div className="p-3 rounded-full border-2 border-dotted border-purple-400 bg-white">
                    <img src={ws1} alt="" className="w-10" />
                  </div>
                  <h1 className="font-bold text-xl ">Lead Generation</h1>
                  <h1 className="text-base m-3">
                    The purpose of a lead generation funnel is to build the
                    audience behind your brand who will, hopefully, become
                    buyers at the end of the funnel.
                  </h1>
                </div>
                <div
                  className="text-black hover:text-white shadow-lg rounded-lg
        p-5 space-y-2 justify-center items-center flex flex-col bg-green-100
        hover:bg-blue-600 transition duration-700 ease-in-out"
                >
                  <div className="p-3 rounded-full border-2 border-dotted border-green-500 bg-white">
                    <img src={ws2} alt="" className="w-10" />
                  </div>
                  <h1 className="font-bold text-xl">Sales Funnel</h1>
                  <h1 className="text-base m-3">
                    While consumers usually think they’re getting a great deal,
                    businesses are really the ones coming out on top.
                  </h1>
                </div>
                <div
                  className="text-black hover:text-white shadow-lg rounded-lg p-5 space-y-3 justify-center 
        items-center flex flex-col hover:bg-blue-600 bg-yellow-100 transition duration-700 ease-in-out"
                >
                  <div className="p-3 rounded-full border-2 border-dotted border-yellow-300 bg-white">
                    <img src={ws3} alt="" className="w-10" />
                  </div>
                  <h1 className="font-bold text-xl">Product Launch</h1>
                  <h1 className="text-base m-3">
                    Sales funnels are also great to put in place even before you
                    have a product to sell. They function similar to lead
                    generation funnels, in that they create an audience for your
                    eventual item.
                  </h1>
                </div>
                <div
                  className="text-black hover:text-white bg-pink-100
                shadow-lg rounded-lg p-5 space-y-4 justify-center 
            items-center flex flex-col hover:bg-blue-600 transition       
            duration-700 ease-in-out"
                >
                  <div className="p-3 rounded-full border-2 border-dotted border-pink-500 bg-white">
                    <img src={ws4} alt="" className="w-10" />
                  </div>
                  <h1 className="font-bold text-xl">Webinar Funnel</h1>
                  <h1 className="text-base m-3">
                    A webinar funnel is a marketing and sales tool that can be
                    used to automatically convert visitors into leads and leads
                    into buyers.
                  </h1>
                </div>
              </div>
            </div>
            <div
              className=" grid grid-cols-1 
        lg:grid-cols-2 place-content-center place-items-center"
            >
              <div
                className="px-10 md:px-10
           lg:p-10 text-center lg:text-left space-y-4"
              >
                <h1 className="text-2xl sm:text-3xl lg:text-5xl link font-bold">
                  <span className="text-blue-600">Webinar</span> Funnel
                </h1>
                <div className="flex flex-col items-center lg:items-start">
                  <div id="loading-bar" className="bg-blue-700 h-1 w-24" />
                </div>
                <h1
                  className="text-sm mx-7 lg:mx-0 sm:text-sm lg:text-base text-justify
            font-medium text-gray-500"
                >
                  A webinar funnel is a marketing and sales tool that can be
                  used to automatically convert visitors into leads and leads
                  into buyers. According to marketing legend, webinars are only
                  effective as top-of-funnel content, intended for a wide
                  audience, and best used as broad educational pieces.
                </h1>
              </div>
              <div className="w-full flex justify-center lg:justify-start">
                <Fade>
                  <img
                    src={two}
                    alt="image"
                    className="w-72 sm:w-96 md:w-6/12 lg:w-10/12"
                  />
                </Fade>
              </div>
            </div>
            <div
              className="grid grid-cols-1 gap-3 m-3 lg:mx-20
        lg:grid-cols-2 place-content-around place-items-center"
            >
              <div className="w-full flex justify-center">
                <Fade>
                  <img
                    src={three}
                    alt="image"
                    className="w-72 sm:w-96 md:w-7/12 lg:w-9/12"
                  />
                </Fade>
              </div>
              <div
                className="
           text-center lg:text-left space-y-4"
              >
                <h1
                  className="text-2xl link font-mediums text-black
            font-bold sm:text-4xl lg:text-5xl"
                >
                  <span className="text-blue-600">Sales</span> Funnel
                </h1>
                <div className="flex flex-col items-center lg:items-start">
                  <div id="loading-bar" className="bg-blue-700 h-1 w-24" />
                </div>
                <h1
                  className="text-sm mx-3 lg:mx-0 sm:text-sm lg:text-base text-justify
            font-medium text-gray-500"
                >
                  A sales funnel is a step-by-step process that allows you to
                  bring your potential customer one step closer to your offer
                  and a buying decision through a series of marketing actions
                  like automated emails, videos, articles, and landing pages
                  that will do the selling for you.
                </h1>
              </div>
            </div>
            {/* <div className="mt-3 pt-12 bg-black">
              <h1 className="text-xs link text-center font-bold text-blue-500">
                IN CASE YOU ARE THINKING
              </h1>
              <h1 className="text-2xl link text-center font-bold text-white sm:text-4xl lg:text-5xl pb-3">
                Do They Convert?
              </h1>
              <div className="flex flex-col items-center">
                <div id="loading-bar" className="bg-blue-700 h-1 w-24 md:mt-5 mb-10 " />
              </div>
              <div className="px-5 space-y-16 w-full items-center flex flex-col justify-center">
                <img
                  src={ft1}
                  alt=""
                  className="sm:w-9/12 md:w-10/12 lg:w-7/12 rounded-xl"
                />
                <img
                  src={ft2}
                  alt=""
                  className="sm:w-9/12 md:w-10/12 lg:w-7/12 rounded-xl"
                />
                <img
                  src={ft3}
                  alt=""
                  className="sm:w-9/12 md:w-10/12 lg:w-7/12 rounded-xl"
                />
                <img
                  src={ft4}
                  alt=""
                  className="sm:w-9/12 md:w-10/12 lg:w-7/12 rounded-xl"
                />
                <img
                  src={ft5}
                  alt=""
                  className="sm:w-9/12 md:w-10/12 lg:w-7/12"
                />
              </div>
            </div>
          */}
          </div>
          <Footer />
        </div>
      ) : (
        <div className="grid place-content-center place-items-center h-screen">
          <span class="h-10 w-10">
            <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-10 w-10 bg-blue-500">
              <span className="grid place-content-center w-full place-items-center">
                <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
                <span class="inline-flex rounded-full h-5 w-5 bg-blue-600"></span>
              </span>
            </span>
          </span>
        </div>
      )}
    </div>
  );
}
export default FunnelServiceComponent;
