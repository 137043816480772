import React from "react";
import { Link } from "react-router-dom";
import { BiMenuAltLeft } from "react-icons/bi";
import { GrFormClose } from "react-icons/gr";
import { BiHomeSmile } from "react-icons/bi";
import { VscSymbolKeyword, VscSymbolMethod } from "react-icons/vsc";
import { GrResources, GrContactInfo } from "react-icons/gr";
import { AiOutlineInsertRowBelow } from "react-icons/ai";
import Logo from "../../image/benefits.png";

export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      state: false,
      show: true,
      scrollPos: 0,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    this.setState({
      scrollPos: document.body.getBoundingClientRect().top,
      show: document.body.getBoundingClientRect().top > this.state.scrollPos,
    });
  };

  render() {
    return (
      <div>
        <div>
          {this.state.state === false ? (
            <div
              style={{ zIndex: 1 }}
              className={`focus-within:flex fixed flex items-center p-3 w-full sm:m-0 
            bg-white justify-between ${
              this.state.scrollPos < -150 ? "shadow-md" : "shadow-none"
            }`}
            >
              <Link to="/">
                <img
                  src={Logo}
                  loading="lazy"
                  alt="logo"
                  className="w-48 sm:hidden"
                />
              </Link>

              <BiMenuAltLeft
                className="text-4xl cursor-pointer sm:hidden"
                onClick={() => this.setState({ state: true })}
              />
            </div>
          ) : null}
          {this.state.state === true ? (
            <div
              style={{ zIndex: 1 }}
              className="bg-white w-full fixed sm:hidden shadow-md"
            >
              <GrFormClose
                className="text-4xl cursor-pointer"
                onClick={() => this.setState({ state: false })}
              />
              <ul className="grid text-xs justify-items-center grid-cols-3 grid-rows-2 gap-10 p-8 text-center">
                <li>
                  <Link to="">
                    <BiHomeSmile
                      className="text-3xl w-full"
                      onClick={() => this.setState({ state: false })}
                    />
                    <span className="font-semibold text-base">Home</span>
                  </Link>
                </li>
                <li>
                  <Link to="/about">
                    <VscSymbolKeyword
                      className="text-3xl w-full"
                      onClick={() => this.setState({ state: false })}
                    />
                    <span className="font-semibold text-base">About</span>
                  </Link>
                </li>
                <li>
                  <Link to="/service">
                    <AiOutlineInsertRowBelow
                      className="text-3xl w-full"
                      onClick={() => this.setState({ state: false })}
                    />
                    <span className="font-semibold text-base">Services</span>
                  </Link>
                </li>
                <li>
                  <Link to="/product">
                    <VscSymbolMethod
                      className="text-3xl w-full"
                      onClick={() => this.setState({ state: false })}
                    />
                    <span className="font-semibold text-base">Products</span>
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    <GrContactInfo
                      className="text-3xl w-full"
                      onClick={() => this.setState({ state: false })}
                    />
                    <span className="font-semibold text-base">Contact</span>
                  </Link>
                </li>
              </ul>
            </div>
          ) : null}
          <div
            style={{ zIndex: 1 }}
            className={`hidden fixed sm:flex bg-white justify-between 
          md:px-10 items-center w-full ${
            this.state.scrollPos < -150 ? "shadow-md" : "shadow-none"
          }`}
          >
            <div>
              <div>
                <Link to="/">
                  <img
                    loading="lazy"
                    src={Logo}
                    alt="logo"
                    className="sm:w-48 sm:ml-5 lg:m-3"
                  />
                </Link>
              </div>
            </div>
            <div>
              <ul
                className="flex flex-grow text-black 
              space-x-3 md:space-x-6 p-5 text-sm lg:text-lg font-semibold"
              >
                <li className="cursor-pointer hover:text-gray-400">
                  <Link to="">
                    <span className="font-semibold text-base">Home</span>
                  </Link>
                </li>
                <li className="cursor-pointer hover:text-gray-400">
                  <Link to="/about">
                    <span className="font-semibold text-base">About</span>
                  </Link>
                </li>
                <li className="cursor-pointer hover:text-gray-400">
                  <Link to="/service">
                    <span className="font-semibold text-base">Services</span>
                  </Link>
                </li>
                <li className="cursor-pointer hover:text-gray-400">
                  <Link to="/product">
                    <span className="font-semibold text-base">Products</span>
                  </Link>
                </li>
                <li className="cursor-pointer hover:text-gray-400">
                  <Link to="/contact">
                    <span className="font-semibold text-base">Contact</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
