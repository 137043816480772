import React, { useState } from "react";
import Header from "../main-com/Header";
import Footer from "../main-com/Footer";
import Tittle from "../sub-com/Tittle";
import App from "../../image/m-icon.png";
import Social from "../../image/s-icon.png";
import Web from "../../image/w-icon.png";
import Funnel from "../../image/funnel.png";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import "../../css/Banner.css";

export default function ServiceComponent() {
  const [state, setState] = useState(false);

  setInterval(() => {
    setState(true);
  }, 1000);

  return (
    <div className="select-none">
      {state ? (
        <div>
          <Header />
          <Tittle title={"Services"} />
          <h1 className="text-4xl link font-medium px-5 pt-5 mt-3 text-center text-black sm:text-6xl">
            Our <span className="text-blue-700">Services</span>
          </h1>
          <div className="flex flex-col items-center">
            <div id="loading-bar" className="bg-blue-700 h-1 w-24 my-3" />
          </div>
          <h1 className="text-gray-400 text-base font-medium text-center mx-10 md:mx-40 lg:mx-80 xl:mx-96">
            We serve the industry in a couple of areas including web, app,
            digital, social aspects. Here you go with details of our services.
          </h1>
          <div
            className="grid grid-cols-1 sm:grid-cols-2 gap-20 
        content-center place-content-center text-center py-12
        mx-5 sm:mx-9 md:mx-12 lg:mx-30 xl:mx-40"
          >
            <div
              className="text-black hover:text-white shadow-lg rounded-lg
        py-10 space-y-2 justify-center items-center flex flex-col 
        hover:bg-blue-900 transition duration-700 ease-in-out"
            >
              <Fade>
                <img src={Funnel} alt="" className="w-32" />
              </Fade>
              <h1 className="font-bold text-2xl ">Sales Funnel</h1>
              <h1 className="text-base mx-3">
                We have some of the best Funnel Hackers in the world. Crafting a
                high converting funnel is our specialty.
              </h1>
              <Link to="/funnel-service">
                <h1 className="font-medium pt-2 link md:text-lg">Read More</h1>
              </Link>
            </div>
            <div
              className="text-black hover:text-white shadow-lg rounded-lg
        py-10 space-y-2 justify-center items-center flex flex-col 
        hover:bg-blue-900 transition duration-700 ease-in-out"
            >
              <Fade>
                <img src={App} alt="" className="w-32" />
              </Fade>
              <h1 className="font-bold text-2xl">App Development</h1>
              <h1 className="text-base mx-3">
                Create an Android App That Stands One in A Million Whether you
                have an idea to create a database-driven android app or
                standalone.
              </h1>
              <Link to="/application-service">
                <h1 className="font-medium pt-2 link md:text-lg">Read More</h1>
              </Link>
            </div>
            <div
              className="text-black hover:text-white shadow-lg rounded-lg py-10 space-y-3 justify-center 
        items-center flex flex-col hover:bg-blue-900 transition duration-700 ease-in-out"
            >
              <Fade>
                <img src={Social} alt="" className="w-32" />
              </Fade>
              <h1 className="font-bold text-2xl">Social Media Marketing</h1>
              <h1 className="text-base mx-3">
                We provide Digital Marketing to make your business more
                profitable, competitive, and efficient.
              </h1>
              <Link to="/marketing-service">
                <h1 className="font-medium pt-2 link md:text-lg">Read More</h1>
              </Link>
            </div>
            <div
              className="text-black hover:text-white  shadow-lg rounded-lg py-10 space-y-4 justify-center 
        items-center flex flex-col hover:bg-blue-900 transition duration-700 ease-in-out"
            >
              <Fade>
                <img src={Web} alt="" className="w-32" />
              </Fade>
              <h1 className="font-bold text-2xl">Web Development</h1>
              <h1 className="text-base mx-3">
                Leading Website Designer and Developer in India It's the art of
                shaping your dreams into an authoritative design or website that
                will serve as a bridge.
              </h1>
              <Link to="/website-service">
                <h1 className="font-medium pt-2 link md:text-lg">Read More</h1>
              </Link>
            </div>
          </div>
          <Footer />
        </div>
      ) : (
        <div className="grid place-content-center place-items-center h-screen">
          <span class="h-10 w-10">
            <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-10 w-10 bg-blue-500">
              <span className="grid place-content-center w-full place-items-center">
                <span class="animate-ping absolute inline-flex h-10 w-10 rounded-full bg-blue-500 opacity-75"></span>
                <span class="inline-flex rounded-full h-5 w-5 bg-blue-600"></span>
              </span>
            </span>
          </span>
        </div>
      )}
    </div>
  );
}
