import React, { useState } from "react";
import Bg from "../../image/contact.png";
import Jump from "react-reveal/Jump";
import emailjs from "emailjs-com";

function ContectUs() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [number, setnumaber] = useState("");
  const [project, setproject] = useState("");
  const [message, setmessage] = useState("");

  function sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_zngrn4j",
        "template_x9p2a6u",
        e.target,
        "user_IuFYBrTrTUebzYcYfcj19"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
    e.target.reset();
  }

  return (
    <section
      id="contact"
      className="bg-white w-full flex justify-center text-white"
    >
      <div className="grid grid-cols-1 py-10 md:grid-cols-2 space-x-9 mt-10">
        <div>
          <div>
            <h1 className="text-4xl text-black font-bold text-center mb-10">
              Have Any Questions About Us?
            </h1>
          </div>
          <div className="space-y-3 text-base p-8 sm:p-10 md:p-16 shadow-xl mx-2 md:mx-6 
          lg:mx-10 xl:mx-16 bg-white rounded-lg">
            <form className="contact-form" onSubmit={sendEmail}>
              <div className="flex flex-col space-y-3">
                <div className="space-y-1">
                  <h1 className="text-black text-base font-medium">Name</h1>
                  <input
                    //value={name}
                    onChange={(event) => setname(event.target.value)}
                    className="focus:outline-none w-full text-black bg-gray-100 focus:shadow-md rounded-md p-5"
                    placeholder="Name"
                    name="name"
                  />
                </div>
                <div className="space-y-1">
                  <h1 className="text-black text-base font-medium">Email</h1>
                  <input
                    //value={email}
                    onChange={(event) => setemail(event.target.value)}
                    className="focus:outline-none w-full text-black bg-gray-100 focus:shadow-md rounded-md p-5"
                    placeholder="Email"
                    name="reply_to"
                  />
                </div>
                <div className="space-y-1">
                  <h1 className="text-black text-base font-medium">Mobile</h1>
                  <input
                    //value={number}
                    onChange={(event) => setnumaber(event.target.value)}
                    className="focus:outline-none w-full text-black bg-gray-100 focus:shadow-md rounded-md p-5"
                    placeholder="Mobile Number"
                    name="number"
                  />
                </div>
                <div className="space-y-1">
                  <h1 className="text-black text-base font-medium">Subject</h1>
                  <input
                    //value={project}
                    onChange={(event) => setproject(event.target.value)}
                    className="focus:outline-none w-full text-black bg-gray-100 focus:shadow-md rounded-md p-5"
                    placeholder="Subject"
                    name="subject"
                  />
                </div>
                <div className="space-y-1">
                  <h1 className="text-black text-base font-medium">Message</h1>
                  <textarea
                    //value={message}
                    onChange={(event) => setmessage(event.target.value)}
                    className="focus:outline-none w-full text-black bg-gray-100 focus:shadow-md rounded-md h-20 p-5"
                    placeholder="Message"
                    name="message"
                  />
                </div>
              </div>
              <div>
                <input
                  className="focus:outline-none flex p-2 bg-blue-900 m-1 mt-10 
                  hover:bg-blue-700 text-white items-center transition duration-700 ease-in-out
                   justify-center rounded-md font-medium cursor-pointer text-md w-full text-center "
                  type="submit"
                  value="Send"
                />
              </div>
            </form>
          </div>
        </div>
        <div className="hidden md:flex">
          <Jump>
            <img
              loading="lazy"
              className="w-full p-0 md:p-5 lg:p-10"
              src={Bg}
            />
          </Jump>
        </div>
      </div>
    </section>
  );
}

export default ContectUs;
